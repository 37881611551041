<template>
    <div class="position-relative">
        <topMenu />
        <section class="blockElement space secondtab-subcontents pb-0">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-12 position-relative">
                        <div class="d-flex flex-wrap align-items-center justify-content-between">
                            <div class="performingMode">
                                <ul id="tabs-nav" class="zuluTabs d-flex align-items-center mb-0">
                                    <!-- v-if="!filterID" -->
                                    <li :class="[{ 'active': tab == 1 }]"><a class="uppercase" href="javascript:void(0)"
                                            @click="changeTab(1)">
                                            {{ $t('view_all_leaders.winningToday') }}</a></li>
                                    <li :class="[{ 'active': tab == 2 }]"><a class="uppercase" href="javascript:void(0)"
                                            @click="changeTab(2)">{{ $t('view_all_leaders.oneWeek') }}</a></li>
                                    <li :class="[{ 'active': tab == 3 }]"><a class="uppercase" href="javascript:void(0)"
                                            @click="changeTab(3)">{{ $t('view_all_leaders.oneMonth') }}</a></li>
                                    <li :class="[{ 'active': tab == 4 }]"><a class="uppercase" href="javascript:void(0)"
                                            @click="changeTab(4)">{{ $t('view_all_leaders.threeMonth') }}</a></li>
                                    <li :class="[{ 'active': tab == 5 }]"><a class="uppercase" href="javascript:void(0)"
                                            @click="changeTab(5)">{{ $t('view_all_leaders.sixMonth') }}</a></li>
                                    <li :class="[{ 'active': tab == 6 }]"><a class="uppercase" href="javascript:void(0)"
                                            @click="changeTab(6)">{{ $t('view_all_leaders.oneYear') }}</a></li>
                                </ul>
                                <div class="filterID d-inline-flex align-items-center"
                                    v-if="filterID && Object.keys(store.allTradersList).length && store.allTradersList.name">
                                    {{
                                        store.allTradersList.name }}
                                    <a class="d-flex" href="javascript:void(0)"
                                        @click="tab = 3; viewsFilterBy = 3; filterID = ''; getAllTradersList()"><vue-feather
                                            size="15" type="x"></vue-feather></a>
                                </div>
                            </div>
                            <div class="d-flex align-items-center leader-filter-right"
                                :class="[{ 'leader-filter-right': !filterID }]">
                                <div class="position-relative customSelectBox d-flex align-items-center">
                                    <div class="position-relative customSelectBoxInner">
                                        <a href="javascript:void(0)"
                                            class="selectDropdown d-flex align-items-center justify-content-between showViews"
                                            @click="showViews = !showViews">
                                            <span class="me-2 d-flex align-items-center">
                                                <span v-text="manualSearch ? '*' : ''"></span>
                                                {{ !customView && !viewsFilterBy ? 'Select View' : customView ? customView :
                                                    viewsTextJson[viewsFilterBy] }} </span>
                                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                                        </a>
                                        <div class="dropdown_menu_animated p-2" :class="[{ 'show': showViews }]">
                                            <div class="customView mb-2">
                                                <p class="f-12 bold mb-1">{{ $t('view_all_leaders.customViews') }}</p>
                                                <a href="javascript:void(0)" class="f-12 d-block ps-2"
                                                    v-if="!getCustomList().length">
                                                    {{ $t('view_all_leaders.noCustomView') }}</a>
                                                <ul v-else>
                                                    <li v-for="list, index in getCustomList()" :key="index">
                                                        <a href="javascript:void(0)" class="f-12 d-block py-1 ps-2"
                                                            @click="filterID = '';viewsFilterBy=0;customView = list.name; customViewDeatil = list; getAllTradersList(list)">{{
                                                                list.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="customView mb-2">
                                                <p class="f-12 bold mb-1">{{ $t('view_all_leaders.defaultViews') }}</p>
                                                <a href="javascript:void(0)" class="f-12 d-block py-1 ps-2"
                                                    @click=" tab = 1; openFilterTab(1)">
                                                    {{ $t('view_all_leaders.winningtodayLower') }}</a>
                                                <a href="javascript:void(0)" class="f-12 d-block py-1 ps-2"
                                                    @click=" tab = 2; openFilterTab(2)">
                                                    {{ $t('view_all_leaders.winningLastWeek') }}
                                                </a>
                                                <a href="javascript:void(0)" class="f-12 d-block py-1 ps-2"
                                                    @click=" tab = 3; openFilterTab(3)">
                                                    {{ $t('view_all_leaders.winningLastMonth') }}
                                                </a>
                                                <a href="javascript:void(0)" class="f-12 d-block py-1 ps-2"
                                                    @click=" tab = 4; openFilterTab(4)">
                                                    {{ $t('view_all_leaders.winningLast3month') }}
                                                </a>
                                                <a href="javascript:void(0)" class="f-12 d-block py-1 ps-2"
                                                    @click=" tab = 5; openFilterTab(5)">
                                                    {{ $t('view_all_leaders.winningLast6month') }}
                                                </a>
                                                <a href="javascript:void(0)" class="f-12 d-block py-1 ps-2"
                                                    @click=" tab = 6; openFilterTab(6)">
                                                    {{ $t('view_all_leaders.winningLastYear') }}
                                                </a>
                                                <a href="javascript:void(0)" class="f-12 d-block py-1 ps-2"
                                                    @click=" openFilterTab(7)">
                                                    {{ $t('view_all_leaders.winning') }}</a>
                                                <a href="javascript:void(0)" class="f-12 d-block py-1 ps-2"
                                                    @click=" openFilterTab(8)">
                                                    {{ $t('view_all_leaders.tradingCryptos') }}</a>
                                                <a href="javascript:void(0)" class="f-12 d-block py-1 ps-2"
                                                    @click=" openFilterTab(9)">
                                                    {{ $t('view_all_leaders.allSortbyZuluRank') }}</a>
                                                <a href="javascript:void(0)" class="f-12 d-block py-1 ps-2"
                                                    @click=" openFilterTab(10)">
                                                    {{ $t('view_all_leaders.roi') }}</a>
                                            </div>
                                            <div class="customView position-sticky mb-0 border-top pt-1">
                                                <a href="javascript:void(0)" class="link f-12 d-flex align-items-center"
                                                    @click="store.user.access_token ? (customViewDeatil = {}, customView = '', clearAll('slider','delete'), openFilterPopup('NEW')) : showLoginPopup=true"><i
                                                        class="me-1 fa fa-plus" aria-hidden="true"></i> {{
                                                            $t('view_all_leaders.createaNewView') }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="javascript:void(0)" class="iconButton ms-2" @click="openFilterPopup('OLD')">
                                        <i class="gray fa fa-filter" aria-hidden="true"></i>
                                    </a>
                                    <div class="position-relative iconButtonMain" v-if="chnageView != 'double'">
                                        <a href="javascript:void(0)" class="iconButton ms-1"
                                            @click="filterComp = !filterComp">
                                            <i class="gray fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown-menu" data-popper-placement="bottom-start"
                                            :class="[{ 'show': filterComp }]">
                                            <li><a class="f-12 dropdown-item" href="#"
                                                    @click="compareTraders = !compareTraders; filterComp = false">{{
                                                        $t('view_all_leaders.compare') }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        class="d-flex align-items-center justify-content-end position-relative form-search-main">
                                        <div class="form-search zuluInput mx-3">
                                            <input type="search" class="form-control" placeholder="Search Leader"
                                                v-model="searchTrder" @keyup="searchTraders()" />
                                            <a href="javascript:void(0)" class="searchIcon"><i class="search"
                                                    data-feather="search"></i></a>
                                        </div>
                                        <div class="common-searchresult cutsom-w520"
                                            :class="[{ 'd-block': store.searchTradersList.length && searchTrder }]">
                                            <div class="pointer trader-list d-flex justify-content-between align-items-center"
                                                v-for="item, key in store.searchTradersList" :key="key"
                                                @click="nextUser(item.providerId)">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="">
                                                        <div class="zlds-avatar-small menuitem">
                                                            <span class="position-relative d-block">
                                                                <v-lazy-image class="rounded-circle"
                                                                    :src="(!item.photoApproved) ? (item.customerAvatarUrl) ? item.customerAvatarUrl : static_vars.imagesURL + 'webservices/Image.ashx?type=provider&size=XS&id=' + item.providerId + '&ignore=false' : static_vars.imagesURL + 'webservices/Image.ashx?type=provider&size=XS&id=' + item.providerId + '&ignore=false'"
                                                                    :alt="item.name" :title="item.name" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column w-100 ps-2">
                                                    <div class="medium f-18 secondary"><span class="">{{ item.name }}</span>
                                                    </div>
                                                    <div class="d-flex justify-content-between">
                                                        <div class="">
                                                            <div class="small inactive">{{ $t('view_all_leaders.following')
                                                            }}</div>
                                                            <span class="">
                                                                ${{ Math.abs(item.amountFollowing) > 999 ?
                                                                    Math.sign(item.amountFollowing) *
                                                                    ((Math.abs(item.amountFollowing) / 1000).toFixed(1)) + 'k' :
                                                                    Math.sign(item.amountFollowing) *
                                                                    Math.abs(item.amountFollowing) }}
                                                            </span>
                                                        </div>
                                                        <div class="">
                                                            <div class="small inactive">{{ $t('view_all_leaders.weeks') }}
                                                            </div>
                                                            <span class="">{{ item.weeks }}</span>
                                                        </div>
                                                        <div class="">
                                                            <div class="small inactive">{{ $t('view_all_leaders.investors')
                                                            }}</div>
                                                            <span class="">{{ item.followers }}</span>
                                                        </div>
                                                        <div class="">
                                                            <div class="small inactive">{{ $t('view_all_leaders.roi') }}
                                                            </div>
                                                            <span class="">{{
                                                                parseFloat(item.rorBasedRoi.toFixed(2)).toLocaleString()
                                                            }}%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center toggleSwitch-layout-main">
                                    <div
                                        class="toggleSwitch-layout iconButton d-flex h-auto align-items-center p-0 w-auto bg-white">
                                        <a href="javascript:void(0)" class="switchToggle left"
                                            @click="chnageView = 'triple'; drawChart()"
                                            :class="[{ 'active': chnageView == 'triple' }]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-list">
                                                <line x1="8" y1="6" x2="21" y2="6" />
                                                <line x1="8" y1="12" x2="21" y2="12" />
                                                <line x1="8" y1="18" x2="21" y2="18" />
                                                <line x1="3" y1="6" x2="3.01" y2="6" />
                                                <line x1="3" y1="12" x2="3.01" y2="12" />
                                                <line x1="3" y1="18" x2="3.01" y2="18" />
                                            </svg>
                                        </a>
                                        <a href="javascript:void(0)" class="switchToggle right"
                                            @click="chnageView = 'double'" :class="[{ 'active': chnageView == 'double' }]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid">
                                                <rect x="3" y="3" width="7" height="7" />
                                                <rect x="14" y="3" width="7" height="7" />
                                                <rect x="14" y="14" width="7" height="7" />
                                                <rect x="3" y="14" width="7" height="7" />
                                            </svg>
                                        </a>
                                    </div>
                                    <!-- <a href="javascript:void(0)" class="iconButton">
                        <i class="gary fa fa-map-marker" aria-hidden="true"></i>
                        </a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="position-relative">
            <Loader :class="'innerLoader'" v-if="loadingAllLeader"></Loader>
            <div class="container-fluid flex-between py-2 bg-white compareTraders"
                v-if="compareTraders && chnageView == 'triple'">
                <div class="d-flex align-items-center flex-wrap" v-if="tradersId.length">
                    <span class="bodyBG p-2 me-2 boxed d-flex align-items-center" v-for="list, index in tradersId"
                        :key="index">
                        <span>{{ getTrdaerName(list) }}</span>
                        <button type="button" class="btn-close ms-1" @click="removeTrader(list)"></button>
                    </span>
                </div>
                <p class="mb-0" v-else>{{ $t('view_all_leaders.selectFewTrader') }}</p>
                <div class="compareClose">
                    <router-link :to="{ path: '/compare', query: { p: tradersId.join(',').toString() } }"
                        :class="[{ 'disabled comButton': tradersId.length == 0 }, { 'button borderBtn comButton': tradersId.length > 0 }]">{{
                            $t('view_all_leaders.compare') }}</router-link>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="compareTraders = false; tradersId = []"></button>
                </div>
            </div>
            <section class="blockElement performance-traders pt-4 tab-content"
                v-if="Object.keys(store.allTradersList).length && store.allTradersList.result && store.allTradersList.result.length && chnageView == 'triple'">
                <div class="container-fluid mb-4">
                    <div class="row">
                        <div class="col-12">

                            <div class="card-body bg-white border boxed d-flex align-items-center mb-3 card-body-rscreen"
                                v-for="item, index in store.allTradersList.result" :key="index">
                                <label class="cutomChechBox" v-if="compareTraders">
                                    <input type="checkbox" v-model="tradersId" :name="'checkbox' + item.trader.providerId"
                                        :value="item.trader.providerId" />
                                    <span class="checkmark"></span>
                                </label>
                                <div class="d-flex gridCol1" v-if="item.trader && Object.keys(item.trader).length">
                                    <div class="position-relative largeIcon">
                                        <span class="top-avtar w-17 h-17 d-block" v-if="item.trader.profile.countryName">
                                            <v-lazy-image width="100" class="d-block"
                                                :src="'/assets/images/country_flag/' + item.trader.profile.countryName.toLowerCase() + '.webp'"
                                                :alt="item.trader.profile.countryName"
                                                :title="item.trader.profile.countryName" />
                                        </span>
                                        <span class="gridImgCircle d-block rounded-circle">
                                            <router-link
                                                :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`"
                                                class="text-main" title="FastWaySignal">
                                                <v-lazy-image class="rounded-circle"
                                                    :src="(!item.trader.profile.photoApproved) ? (item.trader.profile.customerAvatarUrl) ? item.trader.profile.customerAvatarUrl : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false' : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false'"
                                                    alt="FastWaySignal" title="North East Way EA." />
                                            </router-link>
                                        </span>
                                    </div>
                                    <div class="ps-3 ps-md-3"
                                        v-if="item.trader.profile && Object.keys(item.trader.profile).length">
                                        <router-link
                                            :to="`/trader/${item.trader.profile.id}/trading?t=${Object.keys(item.trader.timeframeStats)[0]}&m=1`"
                                            class="text-main" title="FastWaySignal" v-if="item.trader.profile.name">
                                            <h4 class="mb-0">{{ item.trader.profile.name }}</h4>
                                        </router-link>
                                        <div class="smallIcom">
                                          <span v-if="appMode == 'COBRANDED' && item.trader.profile.brokerId">
                                            <v-lazy-image src="/assets/images/logo.svg" width="88" alt="ZuluTrade" title="ZuluTrade" />
                                          </span>
                                          <span v-else-if="appMode == 'WEBSITE' && item.trader.profile.brokerId">
                                            <v-lazy-image :src="static_vars.imagesURL + 'webservices/Image.ashx?type=broker&size=S&id=' + item.trader.profile.brokerId + '&flavor=ZuluTrade'" alt="Other Broker" :title="item.trader.profile.brokerName" />
                                          </span>
                                        </div>
                                        <div class="tooltipbutton" v-if="item.trader.profile.strategyDesc">
                                            <span class="tooltiptext left tooltiptextleader">
                                                <p class="f-12 medium mb-1 flex-between">{{
                                                    $t('view_all_leaders.strategyDescription') }} <a
                                                        href="javascript:void(0)">{{ $t('view_all_leaders.translate') }}</a>
                                                </p>
                                                <p class="f-12">{{ item.trader.profile.strategyDesc }}</p>
                                            </span>
                                            <p class="f-12 mb-0">{{ item.trader.profile.strategyDesc.substr(0, 100) + '...'
                                            }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="charBlock gridCol2 px-3"
                                    v-if="item.trader.timeFrameGraph && Object.keys(item.trader.timeFrameGraph).length && item.trader.timeFrameGraph.series && item.trader.timeFrameGraph.series.length && item.trader.timeFrameGraph.series[0].data && item.trader.timeFrameGraph.series[0].data.length">
                                    <div :id="'allTrader' + item.trader.providerId" class="commonchart"></div>
                                </div>
                                <div class="charBlock gridCol3 p-0">
                                    <table class="trader-listtd w-100">
                                        <tr>
                                            <td>
                                                <div class="">
                                                    <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray"
                                                        :title="$t('view_all_leaders.theSumofTheInvested')">{{
                                                            $t('view_all_leaders.amountFollowing') }}</a>
                                                    <h4 class="f-22 bold green"
                                                        v-if="item.trader.overallStats && item.trader.overallStats.amountFollowing">
                                                        ${{
                                                            Math.round(item.trader.overallStats.amountFollowing).toLocaleString()
                                                        }}
                                                    </h4>
                                                    <h4 class="f-22 bold green" v-else>$0</h4>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="">
                                                    <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray"
                                                        :title="$t('view_all_leaders.theNumberOfLive')">{{
                                                            $t('view_all_leaders.inverstor') }}</a>
                                                    <h4 class="f-18"
                                                        v-if="item.trader.overallStats && item.trader.overallStats.followers">
                                                        {{ item.trader.overallStats.followers.toLocaleString() }}
                                                    </h4>
                                                    <h4 class="f-18" v-else>0</h4>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="">
                                                    <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray"
                                                        :title="$t('view_all_leaders.theNumberOfPips')">{{
                                                            $t('view_all_leaders.minInvestment') }}</a>
                                                    <h4 class="f-18">-</h4>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div class="">
                                                    <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray" title="">
                                                        {{ $t('view_all_leaders.liveInvestmentProfit') }}
                                                    </a>
                                                    <h4 class="f-18 secondary"
                                                        v-if="item.trader.timeframeStats && Object.keys(item.trader.timeframeStats).length && item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].uninterruptedLiveFollowerProfit">
                                                        ${{
                                                            Math.round(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].uninterruptedLiveFollowerProfit).toLocaleString()
                                                        }}
                                                    </h4>
                                                    <h4 class="f-18 secondary" v-else>$0</h4>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="">
                                                    <div class="f-10 mb-1 gray">
                                                        <div class="tooltipbutton">
                                                            <p class="f-12 tooltiptext" v-html="static_vars.roiTooltip"></p>
                                                            <span class="f-10 mb-1 gray">{{ $t('view_all_leaders.roi')
                                                            }}</span>
                                                        </div>
                                                    </div>
                                                    <h4 class="f-18 d-inline-flex green mb-0"
                                                        v-if="item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')"
                                                        :class="ROIClass(item)">{{
                                                            parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi)
                                                            >= 0 ? '+' : '' }}{{
        parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi).toFixed(2)
        || 0 }}%</h4>
                                                </div>
                                            </td>
                                            <!--  <td v-if="chnageView =='triple'"> 
                                        <div class="">
                                            <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray"
                                                title="The difference in pips between the highest and the lowest point reached within the selected timeframe.">MAX
                                                DD PIPS</a>
                                            <h4 class="f-18" v-if="item.trader.timeframeStats && Object.keys(item.trader.timeframeStats).length && item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].overallDrawDown">
                                                {{Math.abs(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].overallDrawDown) > 999 ? Math.sign(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].overallDrawDown)*((Math.abs(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].overallDrawDown)/1000).toFixed(1)) + 'k' : Math.sign(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].overallDrawDown)*Math.abs(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].overallDrawDown)}}
                                            </h4>
                                        </div>
                                    </td>
                                    <td v-if="chnageView =='triple'">
                                        <div class="">
                                            <a href="javascript:void(0)" class="f-10 uppercase mb-2 gray"
                                                title="The number of weeks the Trader is trading.">WEEKS</a>
                                            <h4 class="f-18" v-if="item.trader.overallStats && item.trader.overallStats.weeks">{{item.trader.overallStats.weeks}}</h4>
                                             <h4 class="f-18" v-else>0</h4>
                                        </div>
                                    </td> -->
                                            <td colspan="2" style="vertical-align: middle;">
                                                <div class="d-flex align-items-center">
                                                    <!-- <a href="javascript:void(0)" class="chat secondary me-2">
                            <i class="fa fa-comment-o" aria-hidden="true"></i>
                          </a> -->
                                                    <a class="button fillBtn linkBtn f-10 px-md-4 me-2"
                                                        href="javascript:void(0)" @click="AddCustomView(item)"
                                                        :class="{ 'disabled': store.customerDetail?.readOnly }">{{
                                                            isFollowed(item.trader.providerId) ?
                                                            $t('view_all_leaders.following') : $t('view_all_leaders.follow')
                                                        }}</a>
                                                    <div class="nav-item dropdown">
                                                        <a class="nav-link dropdown-toggle hideArrow" href="#" role="button"
                                                            data-bs-toggle="dropdown" aria-expanded="false"><i
                                                                class="gray fa fa-ellipsis-v" aria-hidden="true"></i></a>
                                                        <ul class="dropdown-menu">
                                                            <li><a class="f-12 dropdown-item" href="javascript:void(0);"
                                                                    @click="sharePop = item.trader.providerId">{{
                                                                        $t('top_traders.share') }}</a></li>
                                                            <li><router-link
                                                                    :to="{ path: '/compare', query: { p: item.trader.profile.id } }"
                                                                    class="f-12 dropdown-item" href="#">{{
                                                                        $t('top_traders.compare') }}</router-link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space fiveColumn flexCol pt-4"
                v-if="Object.keys(store.allTradersList).length && store.allTradersList.result && store.allTradersList.result.length && chnageView == 'double'">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="row g-3">
                                <!--  <div class="col-12">
                            <div class="flex-between mb-3">
                                <p class="f-20 medium mb-0 d-flex align-items-center">
                                <v-lazy-image width="16" height="18" class="me-2" src="/assets/images/lowRisk.png" alt="IconArrow"> {{tradersList.name}}</p>
                                <a class="filterBtn d-flex align-items-center" href="javascript:void(0)"><vue-feather class="me-1" type="filter" size="16"></vue-feather> Filters</a>
                            </div>
                        </div> -->
                                <div class="col-12 col-lg-3" v-for="list, key in store.allTradersList.result" :key="key">
                                    <div class="card shadow-none h-100">
                                        <div class="card-body px-0">
                                            <div class="text-center position-relative">
                                                <div class="vueRisk px-3 d-flex align-items-start justify-content-between">
                                                    <div class="minCustom text-start">
                                                        <p :title="static_vars.riskTooltip"
                                                            :class="[{ 'blueBtn': list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].timeFrameRisk == 1 }, { 'greenBtn': list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].timeFrameRisk == 2 }, { 'yellowBtn': list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].timeFrameRisk == 3 }, { 'redBtn': list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].timeFrameRisk == 4 }, { 'pinkBtn': list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].timeFrameRisk == 5 }]"
                                                            class="bTn mb-0 f-12 bold uppercase"
                                                            v-if="list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].hasOwnProperty('timeFrameRisk')">
                                                            {{ $t('top_traders.risk') }}
                                                            {{
                                                                list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].timeFrameRisk
                                                            }}</p>
                                                    </div>
                                                    <!--yellowBtn, dashgreen, blueBtn-->
                                                    <router-link :to="'/trader/' + list.trader.profile.id + '/trading'"
                                                        class="circleImg d-inline-block mb-2">
                                                        <v-lazy-image
                                                            :src="static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + list.trader.profile.zuluAccountId + '&ignore=false'"
                                                            :alt="list.trader.profile.name"
                                                            :title="list.trader.profile.name" />
                                                    </router-link>
                                                    <div class="d-flex align-items-center minCustom justify-content-end">
                                                        <a href="javascript:void(0)" @click="AddCustomView(list)"
                                                            :class="{ 'disabled': store.customerDetail?.readOnly }"><vue-feather
                                                                type="star" size="19"
                                                                :class="[{ 'filled': isFollowed(list.trader.providerId) }]"></vue-feather></a>
                                                        <div class="position-relative">
                                                            <a href="javascript:void(0)" class="iconButton ms-1"
                                                                @click="(opendots != list.trader.profile.zuluAccountId) ? opendots = list.trader.profile.zuluAccountId : opendots = -1">
                                                                <i class="gray fa fa-ellipsis-v" aria-hidden="true"></i>
                                                            </a>
                                                            <ul class="dropdown-menu" data-popper-placement="bottom-start"
                                                                :class="[{ 'show': opendots == list.trader.profile.zuluAccountId }]">

                                                                <li><a class="f-12 dropdown-item" href="javascript:void(0);"
                                                                        @click="sharePop = list.trader.providerId">{{
                                                                            $t('top_traders.share') }}</a></li>
                                                                <li><router-link
                                                                        :to="{ path: '/compare', query: { p: list.trader.profile.id } }"
                                                                        class="f-12 dropdown-item">{{
                                                                            $t('top_traders.compare') }}</router-link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="title">
                                                    <router-link
                                                        :to="`/trader/${list.trader.profile.id}/trading?t=${Object.keys(list.trader.timeframeStats)[0]}&m=1`">
                                                        <h6 class="line-1 mb-2 f-16 medium">{{ list.trader.profile.name ||
                                                            '' }}</h6>
                                                        <div
                                                            class="element d-flex align-items-center justify-content-center mb-2 px-2">
                                                            <div class="element d-flex align-items-center justify-content-center"
                                                                :class="[{ 'me-3': (list.trader.overallStats && list.trader.overallStats.includedInWatchlist) }]">
                                                                <p class="gray medium f-12 mb-0 me-1">{{
                                                                    $t('top_traders.copiers') }}</p>
                                                                <p class="f-12 bold mb-0">{{
                                                                    list.trader.overallStats.followers || 0 }}</p>
                                                            </div>
                                                            <div class="element d-flex align-items-center justify-content-center"
                                                                v-if="list.trader.overallStats && list.trader.overallStats.includedInWatchlist">
                                                                <p class="gray medium f-12 mb-0 me-1">{{
                                                                    $t('top_traders.followers') }}</p>
                                                                <p class="f-12 bold mb-0">{{
                                                                    list.trader.overallStats.includedInWatchlist || 0 }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                    <div class="element"
                                                        v-if="list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].hasOwnProperty('annualizedRorBasedRoi')">
                                                        <p class="mb-0 px-1 bold d-inline-flex border-0"
                                                            :class="ROIClass(list)">
                                                            {{
                                                                parseInt(list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].annualizedRorBasedRoi)
                                                                >= 0 ? '+' : '' }}{{
        parseFloat(list.trader.timeframeStats[Object.keys(list.trader.timeframeStats)[0]].annualizedRorBasedRoi).toFixed(2)
        || 0 }}%
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space"
                v-else-if="!Object.keys(store.allTradersList).length || !store.allTradersList.result?.length">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <Nodata></Nodata>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space pt-0"
                v-if="Object.keys(store.allTradersList).length && store.allTradersList.result?.length > 49 && store.allTradersList.result.length && !filterID">
                <!-- !filterID && !store.loading &&  -->
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-2">
                            <a class="button fillBtn zulu_btn large me-3 px-md-4" href="javascript:void(0);"
                                @click.prevent="() => loadMore()">
                                <Spinner class="buttonSpiner" v-if="loadingAllLeader"></Spinner> {{
                                    $t('leader_profile.loadMore') }}
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <!-- <Spinner v-if="store.loading"></Spinner> -->
            <Nodata v-if="!Object.keys(store.allTradersList).length"></Nodata>
        </div>
        <!-- MODAL All Trader Filter  -->
        <div class="modal fade allTraderFilter" :class="[{ 'show d-block': filterPop }]" id="advancedSearch"
            aria-labelledby="staticBackdropLabel" v-show="filterPop">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <p class="mb-0 f-20 fw-300">{{ $t('view_all_leaders.advancedSearch') }}</p>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            @click="filterPop = false"></button>
                    </div>
                    <div class="modal-body">
                        <div class="block1 dashed pb-3 mb-3 d-flex align-items-center justify-content-between">
                            <div class="form-group d-flex align-items-center mb-0 winningSearch">
                                <label>{{ $t('view_all_leaders.name') }}</label>
                                <input type="text" :placeholder="$t('view_all_leaders.namePlaceholder')"
                                    class="form-control radius-0 mx-3" name="" v-model="customView" />
                                <a href="javascript:void(0)" class="add" :title="$t('view_all_leaders.save')"
                                    :class="{ 'disabled': store.customerDetail?.readOnly }"
                                    v-if="store.user.access_token && Object.keys(customViewDeatil).length"
                                    @click="AddCustomView('', 'EDIT'); setLocalStorageFilters()"><vue-feather class="w-16"
                                        type="save"></vue-feather></a>
                                <a href="javascript:void(0)" class="add" :title="$t('view_all_leaders.add')"
                                    @click="AddCustomView(); setLocalStorageFilters()"
                                    :class="{ 'disabled': store.customerDetail?.readOnly }"><vue-feather class="w-16 mx-2"
                                        type="plus"></vue-feather></a>
                                <a href="javascript:void(0)" class="add" :title="$t('view_all_leaders.delete')"
                                    :class="{ 'disabled': store.customerDetail?.readOnly }"
                                    v-if="store.user.access_token && Object.keys(customViewDeatil).length"
                                    @click="deleteCustomView(); clearAll('slider','delete')"><vue-feather class="red w-16"
                                        type="trash-2"></vue-feather></a>
                            </div>
                            <div class="dualButton d-flex align-items-center justify-content-end">
                                <a href="javascript:void(0)" class="button zulu_btn rounded border-button me-2"
                                    v-if="customView" @click="clearAll('slider')">{{ $t('view_all_leaders.clear') }}</a>
                                <a href="javascript:void(0)" class="button zulu_btn fillBtn"
                                    @click="filterID = ''; setLocalStorageFilters('SEARCH')">{{
                                        $t('view_all_leaders.search') }}</a>
                            </div>
                        </div>
                        <div class="block2 dashed pb-3 mb-3 row">
                            <div class="col-12 col-lg-6">
                                <div class="form-group d-flex align-items-center mb-2 Timeframe">
                                    <label class="me-3 minAdd">{{ $t('view_all_leaders.timeframe') }}</label>
                                    <div class="position-relative advancedMenu">
                                        <a href="javascript:void(0)" @click="showTimeframe = !showTimeframe"
                                            class="selectDropdown showDropDown bg-white d-flex align-items-center justify-content-between">
                                            {{ timeframeJSON[timeframeVal] }} <i class="fa fa-caret-down"
                                                aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown_menu_animated week py-2" :class="[{ 'show': showTimeframe }]">
                                            <li v-for="value, key in timeframeJSON" :key="key"><a href="javascript:void(0)"
                                                    @click="timeframeVal = key; showTimeframe = false"
                                                    :class="[{ 'active': timeframeVal == key }]">{{ value }}</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form-group d-flex align-items-center mb-0 showCountry">
                                    <label class="me-3 minAdd">{{ $t('view_all_leaders.countries') }}</label>
                                    <div class="position-relative advancedMenu">
                                        <a href="javascript:void(0)"
                                            class="selectDropdown bg-white d-flex align-items-center justify-content-between"
                                            @click="showCountry = !showCountry">
                                            <!-- <span class="d-flex align-items-center" v-if="includeCountry.length > 0 && includeCountry.length < 3"> -->
                                            <span class="d-flex align-items-center" v-if="includeCountry.length > 0">
                                                <!-- <v-lazy-image class="me-2" width="20" src="../../../public/assets/images/country_flag/india.webp" alt="Flag" title="Country Code" /> -->
                                                {{ includeCountry.length }} {{ (includeCountry.length == 1) ?
                                                    $t('view_all_leaders.countryText') :
                                                    $t('view_all_leaders.countriesText') }}
                                            </span>
                                            <span v-else>{{ $t('view_all_leaders.selectCountry') }}</span>
                                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown_menu_animated week hasChecked"
                                            :class="[{ 'show': showCountry }]">
                                            <li class="searchDropdown">
                                                <input type="search" :placeholder="$t('view_all_leaders.searchCountry')"
                                                    class="form-control searchCountry" name="" v-model="searchCountry" />
                                            </li>
                                            <li v-for="item, index in getCountries" :key="index">
                                                <a href="javascript:void(0)" class="f-12 d-flex align-items-center"
                                                    @click="filterCountry(item)"
                                                    :class="{ 'active': includeCountry.indexOf(item.countryId) > -1 }">
                                                    <v-lazy-image class="me-2" width="15"
                                                        :src="'/assets/images/country_flag/' + item.countryName.toLowerCase() + '.webp'"
                                                        alt="Flag" :title="item.countryName" /> {{ item.countryName }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="form-group d-flex align-items-center mb-2">
                                    <label class="me-3 minAdd">{{ $t('view_all_leaders.currencies') }}</label>
                                    <div class="position-relative advancedMenu Currencies">
                                        <a href="javascript:void(0)"
                                            class="selectDropdown bg-white d-flex align-items-center justify-content-between"
                                            @click="showCurrency = !showCurrency">
                                            {{ (includeCurrency.length > 0) ? includeCurrency.length :
                                                $t('view_all_leaders.anyCcurrencyPair')
                                            }} {{ (includeCurrency.length > 0) ? (includeCurrency.length == 1) ?
    $t('view_all_leaders.currencyText') : $t('view_all_leaders.currenciesText') : ''
}}
                                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown_menu_animated week py-2" :class="[{ 'show': showCurrency }]">
                                            <li v-for="list, key in store.TradercurrencyList" :key="key"><a
                                                    :class="{ 'active': includeCurrency.indexOf(list) > -1 }"
                                                    href="javascript:void(0)" @click="filterCurrency(list)">{{ list }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form-group d-flex align-items-center mb-2">
                                    <label class="me-3 minAdd">{{ $t('view_all_leaders.sortBy') }}</label>
                                    <div class="position-relative advancedMenu">
                                        <a href="javascript:void(0)"
                                            class="selectDropdown bg-white d-flex align-items-center justify-content-between showSortBy"
                                            @click="showSortBy = !showSortBy">
                                            {{ SortByJson[sortByKey] }} <i class="fa fa-caret-down" aria-hidden="true"></i>
                                        </a>
                                        <ul class="dropdown_menu_animated week py-2" :class="[{ 'show': showSortBy }]">
                                            <li v-for="value, key in SortByJson" :key="key"><a
                                                    :class="[{ 'active': sortByKey == key }]" href="javascript:void(0)"
                                                    @click="sortByKey = key; showSortBy = false">{{ value }}</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form-group d-flex align-items-center mb-0">
                                    <label class="me-3 minAdd">&nbsp;</label>
                                    <select class="form-select form-control w-auto radius-0" v-model="sortBy">
                                        <option value="asc">{{ $t('view_all_leaders.ascending') }}</option>
                                        <option value="desc">{{ $t('view_all_leaders.descending') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="block3 mb-3 row">
                            <ul class="d-flex align-items-center flex-wrap labelCheckBox">
                                <li>
                                    <label class="cutomChechBox">
                                        {{ $t('view_all_leaders.areIinTheTop100') }}
                                        <input type="checkbox" v-model="topHun" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="cutomChechBox">
                                        {{ $t('view_all_leaders.haveApproved') }}
                                        <input type="checkbox" v-model="photo" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="cutomChechBox">
                                        {{ $t('view_all_leaders.tradedWithin') }}
                                        <input type="checkbox" v-model="lastweek" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="cutomChechBox">
                                        {{ $t('view_all_leaders.haveBeenRated') }}
                                        <input type="checkbox" v-model="Investors" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="cutomChechBox">
                                        {{ $t('view_all_leaders.haveLiveUsers') }}
                                        <input type="checkbox" v-model="subscribed" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="cutomChechBox">
                                        {{ $t('view_all_leaders.haveStrategy') }}
                                        <input type="checkbox" v-model="strategy" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="cutomChechBox">
                                        {{ $t('view_all_leaders.areNotTrading') }}
                                        <input type="checkbox" v-model="exotics" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="cutomChechBox">
                                        {{ $t('view_all_leaders.tradingCryptos') }}
                                        <input type="checkbox" v-model="cryptos" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                            </ul>
                            <div class="columnBlock">
                                <p class="f-14 medium mb-2">{{ $t('view_all_leaders.arecharacterizedas') }}</p>
                                <ul class="d-flex align-items-center flex-wrap inRow">
                                    <li>
                                        <label class="cutomChechBox">
                                            <v-lazy-image src="/assets/images/icon/badge-dollar-blue.png" />
                                            <input type="checkbox" v-model="characterizedJson.hasLiveAccount" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="cutomChechBox">
                                            <v-lazy-image src="/assets/images/icon/badge-dollar-green.png" />
                                            <input type="checkbox"
                                                v-model="characterizedJson.hasDemoAccountAndLiveLinkedToProvider" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="cutomChechBox">
                                            <v-lazy-image src="/assets/images/icon/tick.png" />
                                            <input type="checkbox" v-model="characterizedJson.partiallyVerified" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="cutomChechBox">
                                            <v-lazy-image src="/assets/images/icon/badge-zuluscript.png" />
                                            <input type="checkbox" v-model="characterizedJson.hasZuluscripts" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="cutomChechBox">
                                            <v-lazy-image src="/assets/images/icon/badge-api.png" />
                                            <input type="checkbox" v-model="characterizedJson.hasApi" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="cutomChechBox">
                                            <v-lazy-image src="/assets/images/icon/badge-fifo.png" />
                                            <input type="checkbox" v-model="characterizedJson.fifo" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="cutomChechBox">
                                            <v-lazy-image src="/assets/images/icon/badge-star.png" />
                                            <input type="checkbox" v-model="characterizedJson.ratingStar" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="cutomChechBox">
                                            <v-lazy-image src="/assets/images/icon/badge-scale.png" />
                                            <input type="checkbox"
                                                v-model="characterizedJson.tradingCorrelatedCurrencies" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="cutomChechBox">
                                            <v-lazy-image src="/assets/images/icon/veteran.png" />
                                            <input type="checkbox" v-model="characterizedJson.zuluVeteran" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="cutomChechBox">
                                            <v-lazy-image src="/assets/images/icon/badge-calendar.png" />
                                            <input type="checkbox" v-model="characterizedJson.tradingEconomicEvents" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="cutomChechBox">
                                            <v-lazy-image src="/assets/images/icon/badge-ea.png" />
                                            <input type="checkbox" v-model="characterizedJson.check" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="position-relative rangeSlider mb-3">
                                        <div class="d-flex align-items-center">
                                            <label class="f-14 me-2">{{ $t('view_all_leaders.runningWeeks') }}</label>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="300"
                                                v-model="runningWeeks.left"
                                                @keyup="changeSlider($event, 'left1', 'LEFT', 'runningWeeks')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                            <p class="mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="300"
                                                v-model="runningWeeks.right"
                                                @keyup="changeSlider($event, 'right1', 'RIGHT', 'runningWeeks')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                        </div>
                                        <div slider id="slider-distance">
                                            <div>
                                                <div inverse-left style="width: 90%;"></div>
                                                <div inverse-right style="width: 70%;"></div>
                                                <div range style="left: 0%; right: 0%;"></div>
                                                <span thumb style="left: 0%;"></span>
                                                <span thumb style="left: 100%;"></span>
                                                <div sign style="left: 0%;">
                                                    <span id="value">0</span>
                                                </div>
                                                <div sign style="left: 100%;">
                                                    <span id="value">300</span>
                                                </div>
                                            </div>
                                            <input type="range" tabindex="0" max="300" min="0" step="1"
                                                @input="leftSlider($event, 'runningWeeks')" ref="left1"
                                                v-model="runningWeeks.left" />

                                            <input type="range" tabindex="0" v-model="runningWeeks.right" max="300" min="0"
                                                step="1" ref="right1" @input="rightSlider($event, 'runningWeeks')" />
                                        </div>
                                    </div>
                                    <div class="position-relative rangeSlider mb-3">
                                        <div class="d-flex align-items-center">
                                            <label class="f-14 me-2">{{ $t('view_all_leaders.maxDD') }}</label>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="100"
                                                v-model="maxDD.left" @keyup="changeSlider($event, 'left2', 'LEFT', 'maxDD')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                            <p class="mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="100"
                                                v-model="maxDD.right"
                                                @keyup="changeSlider($event, 'right2', 'RIGHT', 'maxDD')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                        </div>
                                        <div slider id="slider-distance">
                                            <div>
                                                <div inverse-left style="width: 90%;"></div>
                                                <div inverse-right style="width: 70%;"></div>
                                                <div range style="left: 0%; right: 0%;"></div>
                                                <span thumb style="left: 0%;"></span>
                                                <span thumb style="left: 100%;"></span>
                                                <div sign style="left: 0%;">
                                                    <span id="value">0</span>
                                                </div>
                                                <div sign style="left: 100%;">
                                                    <span id="value">100</span>
                                                </div>
                                            </div>
                                            <input type="range" tabindex="0" max="100" min="0" step="1"
                                                @input="leftSlider($event, 'maxDD')" ref="left2" v-model="maxDD.left" />

                                            <input type="range" tabindex="0" v-model="maxDD.right" max="100" min="0"
                                                step="1" ref="right2" @input="rightSlider($event, 'maxDD')" />
                                        </div>
                                    </div>
                                    <div class="position-relative rangeSlider mb-3">
                                        <div class="d-flex align-items-center">
                                            <label class="f-14 me-2">Trader Slippage</label>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="20"
                                                v-model="slippage.left"
                                                @keyup="changeSlider($event, 'left3', 'LEFT', 'slippage')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                            <p class="mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="20"
                                                v-model="slippage.right"
                                                @keyup="changeSlider($event, 'right3', 'RIGHT', 'slippage')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                        </div>
                                        <div slider id="slider-distance">
                                            <div>
                                                <div inverse-left style="width: 90%;"></div>
                                                <div inverse-right style="width: 70%;"></div>
                                                <div range style="left: 0%; right: 0%;"></div>
                                                <span thumb style="left: 0%;"></span>
                                                <span thumb style="left: 100%;"></span>
                                                <div sign style="left: 0%;">
                                                    <span id="value">0</span>
                                                </div>
                                                <div sign style="left: 100%;">
                                                    <span id="value">20</span>
                                                </div>
                                            </div>
                                            <input type="range" tabindex="0" max="20" min="0" step="1"
                                                @input="leftSlider($event, 'slippage')" ref="left3"
                                                v-model="slippage.left" />

                                            <input type="range" tabindex="0" v-model="slippage.right" max="20" min="0"
                                                step="1" ref="right3" @input="rightSlider($event, 'slippage')" />
                                        </div>
                                    </div>
                                    <div class="position-relative rangeSlider mb-3">
                                        <div class="d-flex align-items-center">
                                            <label class="f-14 me-2">{{ $t('view_all_leaders.minInvestmentDollar')
                                            }}</label>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="100000"
                                                v-model="minInvestment.left"
                                                @keyup="changeSlider($event, 'left4', 'LEFT', 'minInvestment')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                            <p class="mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="100000"
                                                v-model="minInvestment.right"
                                                @keyup="changeSlider($event, 'right4', 'RIGHT', 'minInvestment')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                        </div>
                                        <div slider id="slider-distance">
                                            <div>
                                                <div inverse-left style="width: 90%;"></div>
                                                <div inverse-right style="width: 70%;"></div>
                                                <div range style="left: 0%; right: 0%;"></div>
                                                <span thumb style="left: 0%;"></span>
                                                <span thumb style="left: 100%;"></span>
                                                <div sign style="left: 0%;">
                                                    <span id="value">0</span>
                                                </div>
                                                <div sign style="left: 100%;">
                                                    <span id="value">100000</span>
                                                </div>
                                            </div>
                                            <input type="range" tabindex="0" max="100000" min="0" step="1"
                                                @input="leftSlider($event, 'minInvestment')" ref="left4"
                                                v-model="minInvestment.left" />

                                            <input type="range" tabindex="0" v-model="minInvestment.right" max="100000"
                                                min="0" step="1" ref="right4"
                                                @input="rightSlider($event, 'minInvestment')" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="position-relative rangeSlider mb-3">
                                        <div class="d-flex align-items-center">
                                            <label class="f-14 me-2">{{ $t('view_all_leaders.avgPips') }}</label>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="1000"
                                                v-model="avgpips.left"
                                                @keyup="changeSlider($event, 'left5', 'LEFT', 'avgpips')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                            <p class="mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="1000"
                                                v-model="avgpips.right"
                                                @keyup="changeSlider($event, 'right5', 'RIGHT', 'avgpips')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                        </div>
                                        <div slider id="slider-distance">
                                            <div>
                                                <div inverse-left style="width: 90%;"></div>
                                                <div inverse-right style="width: 70%;"></div>
                                                <div range style="left: 0%; right: 0%;"></div>
                                                <span thumb style="left: 0%;"></span>
                                                <span thumb style="left: 100%;"></span>
                                                <div sign style="left: 0%;">
                                                    <span id="value">0</span>
                                                </div>
                                                <div sign style="left: 100%;">
                                                    <span id="value">1000</span>
                                                </div>
                                            </div>
                                            <input type="range" tabindex="0" max="1000" min="0" step="1"
                                                @input="leftSlider($event, 'avgpips')" ref="left5" v-model="avgpips.left" />

                                            <input type="range" tabindex="0" v-model="avgpips.right" max="1000" min="0"
                                                step="1" ref="right5" @input="rightSlider($event, 'avgpips')" />
                                        </div>
                                    </div>
                                    <div class="position-relative rangeSlider mb-3">
                                        <div class="d-flex align-items-center">
                                            <label class="f-14 me-2">{{ $t('view_all_leaders.maxDDPips') }}</label>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="100"
                                                v-model="DDPercent.left"
                                                @keyup="changeSlider($event, 'left6', 'LEFT', 'DDPercent')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                            <p class="mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="100"
                                                v-model="DDPercent.right"
                                                @keyup="changeSlider($event, 'right6', 'RIGHT', 'DDPercent')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                        </div>
                                        <div slider id="slider-distance">
                                            <div>
                                                <div inverse-left style="width: 90%;"></div>
                                                <div inverse-right style="width: 70%;"></div>
                                                <div range style="left: 0%; right: 0%;"></div>
                                                <span thumb style="left: 0%;"></span>
                                                <span thumb style="left: 100%;"></span>
                                                <div sign style="left: 0%;">
                                                    <span id="value">0</span>
                                                </div>
                                                <div sign style="left: 100%;">
                                                    <span id="value">100</span>
                                                </div>
                                            </div>
                                            <input type="range" tabindex="0" max="100" min="0" step="1"
                                                @input="leftSlider($event, 'DDPercent')" ref="left6"
                                                v-model="DDPercent.left" />

                                            <input type="range" tabindex="0" v-model="DDPercent.right" max="100" min="0"
                                                step="1" ref="right6" @input="rightSlider($event, 'DDPercent')" />
                                        </div>
                                    </div>
                                    <div class="position-relative rangeSlider mb-3">
                                        <div class="d-flex align-items-center">
                                            <label class="f-14 me-2">{{ $t('view_all_leaders.correlationPercent') }}</label>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="100"
                                                v-model="CorPercent.left" @keyup="changeSlider($event, 'left7', 'LEFT')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                            <p class="mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="100"
                                                v-model="CorPercent.right" @keyup="changeSlider($event, 'right7', 'RIGHT')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                        </div>
                                        <div slider id="slider-distance">
                                            <div>
                                                <div inverse-left style="width: 90%;"></div>
                                                <div inverse-right style="width: 70%;"></div>
                                                <div range style="left: 0%; right: 0%;"></div>
                                                <span thumb style="left: 0%;"></span>
                                                <span thumb style="left: 100%;"></span>
                                                <div sign style="left: 0%;">
                                                    <span id="value">0</span>
                                                </div>
                                                <div sign style="left: 100%;">
                                                    <span id="value">100</span>
                                                </div>
                                            </div>
                                            <input type="range" tabindex="0" max="100" min="0" step="1"
                                                @input="leftSlider($event, 'CorPercent')" ref="left7"
                                                v-model="CorPercent.left" />

                                            <input type="range" tabindex="0" v-model="CorPercent.right" max="100" min="0"
                                                step="1" ref="right7" @input="rightSlider($event, 'CorPercent')" />
                                        </div>
                                    </div>
                                    <div class="position-relative rangeSlider mb-3">
                                        <div class="d-flex align-items-center">
                                            <label class="f-14 me-2">{{ $t('view_all_leaders.win') }}</label>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="100"
                                                v-model="winPercent.left"
                                                @keyup="changeSlider($event, 'left8', 'LEFT', 'winPercent')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                            <p class="mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue"
                                                :placeholder="$t('view_all_leaders.value')" name="" min="0" max="100"
                                                v-model="winPercent.right"
                                                @keyup="changeSlider($event, 'right8', 'RIGHT', 'winPercent')"
                                                onkeydown="if(event.key==='-'){event.preventDefault();}" />
                                        </div>
                                        <div slider id="slider-distance">
                                            <div>
                                                <div inverse-left style="width: 90%;"></div>
                                                <div inverse-right style="width: 70%;"></div>
                                                <div range style="left: 0%; right: 0%;"></div>
                                                <span thumb style="left: 0%;"></span>
                                                <span thumb style="left: 100%;"></span>
                                                <div sign style="left: 0%;">
                                                    <span id="value">0</span>
                                                </div>
                                                <div sign style="left: 100%;">
                                                    <span id="value">100</span>
                                                </div>
                                            </div>
                                            <input type="range" tabindex="0" max="100" min="0" step="1"
                                                @input="leftSlider($event, 'winPercent')" ref="left8"
                                                v-model="winPercent.left" />

                                            <input type="range" tabindex="0" v-model="winPercent.right" max="100" min="0"
                                                step="1" ref="right8" @input="rightSlider($event, 'winPercent')" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="d-flex align-items-center pipRange">
                                        <label class="minAdd">{{ $t('view_all_leaders.pips') }}</label>
                                        <div class="d-flex align-items-center">
                                            <p class="f-14 mb-0 me-2">{{ $t('view_all_leaders.from') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="pips.from" />
                                            <p class="f-14 mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="pips.to" />
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center pipRange">
                                        <label class="minAdd">{{ $t('view_all_leaders.maxDDpips') }}</label>
                                        <div class="d-flex align-items-center">
                                            <p class="f-14 mb-0 me-2">{{ $t('view_all_leaders.from') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="maxDDPips.from" />
                                            <p class="f-14 mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="maxDDPips.to" />
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center pipRange">
                                        <label class="minAdd">{{ $t('view_all_leaders.worstTradepips') }}</label>
                                        <div class="d-flex align-items-center">
                                            <p class="f-14 mb-0 me-2">{{ $t('view_all_leaders.from') }}</p>
                                            <input type="number" class="showValue w-100" name=""
                                                v-model="worstTrade.from" />
                                            <p class="f-14 mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="worstTrade.to" />
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center pipRange">
                                        <label class="minAdd">{{ $t('view_all_leaders.bestTradepips') }}</label>
                                        <div class="d-flex align-items-center">
                                            <p class="f-14 mb-0 me-2">{{ $t('view_all_leaders.from') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="bestTrade.from" />
                                            <p class="f-14 mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="bestTrade.to" />
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center pipRange">
                                        <label class="minAdd">{{ $t('view_all_leaders.maxOpenTrades') }}</label>
                                        <div class="d-flex align-items-center">
                                            <p class="f-14 mb-0 me-2">{{ $t('view_all_leaders.from') }}</p>
                                            <input type="number" class="showValue w-100" name=""
                                                v-model="openTrades.from" />
                                            <p class="f-14 mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="openTrades.to" />
                                            <div class="form-group d-flex align-items-center mb-0 me-3">
                                                <select class="form-select form-control w-auto radius-0"
                                                    v-model="openTrades.timeframe">
                                                    <option value=""></option>
                                                    <option value="90">3m</option>
                                                    <option value="90">6m</option>
                                                    <option value="365">12m</option>
                                                </select>
                                                <label class="ms-3">{{ $t('view_all_leaders.bestTrade') }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="d-flex align-items-center pipRange">
                                        <label class="minAdd">NME ($)</label>
                                        <div class="d-flex align-items-center">
                                            <p class="f-14 mb-0 me-2">{{ $t('view_all_leaders.from') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="NME.from" />
                                            <p class="f-14 mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="NME.to" />
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center pipRange">
                                        <label class="minAdd">{{ $t('view_all_leaders.numberOfTrades') }}</label>
                                        <div class="d-flex align-items-center">
                                            <p class="f-14 mb-0 me-2">{{ $t('view_all_leaders.from') }}</p>
                                            <input type="number" class="showValue w-100" name=""
                                                v-model="noOfTrades.from" />
                                            <p class="f-14 mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="noOfTrades.to" />
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center pipRange">
                                        <label class="minAdd">{{ $t('view_all_leaders.investors') }}</label>
                                        <div class="d-flex align-items-center">
                                            <p class="f-14 mb-0 me-2">{{ $t('view_all_leaders.from') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="Investor.from" />
                                            <p class="f-14 mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue w-100" name="" v-model="Investor.to" />
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center pipRange">
                                        <label class="minAdd">{{ $t('view_all_leaders.avgTradeTime') }}</label>
                                        <div class="d-flex align-items-center">
                                            <p class="f-14 mb-0 me-2">{{ $t('view_all_leaders.from') }}</p>
                                            <input type="number" class="showValue w-100" name=""
                                                v-model="avgTradeTime.from" />
                                            <p class="f-14 mb-0 mx-2">{{ $t('view_all_leaders.to') }}</p>
                                            <input type="number" class="showValue w-100" name=""
                                                v-model="avgTradeTime.to" />
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center pipRange">
                                        <div class="form-group d-flex align-items-center mb-0">
                                            <select class="form-select form-control w-auto radius-0" v-model="compareTrade">
                                                <option value=""></option>
                                                <option value="gt">&gt;</option>
                                                <option value="lt">&lt;</option>
                                            </select>
                                            <label class="ms-3">{{ $t('view_all_leaders.worstTrade') }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <sharePop :shareURL="sharePop" :close="() => sharePop = ''" v-if="sharePop" :shareType="'leaderProfile'"></sharePop>
        <!-- MODAL START ALERT SIGNIN  -->
        <loginPopup v-if="showLoginPopup"></loginPopup>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import topMenu from "./topMenu";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import sharePop from "@/views/social-feed/share-pop"
import $ from "jquery";
import { useToast } from "vue-toastification";
import _ from 'lodash'
export default {
    setup() {
        const store = myStore();
        const toast = useToast();
        return { store, toast };
    },
    data() {
        return {
            appMode : process.env.VUE_APP_MODE,
            loadingAllLeader: false,
            sharePop: '',
            opendots: -1,
            tradersId: [],
            compareTraders: false,
            showLoginPopup: false,
            isCustomView: false,
            customViewDeatil: {},
            tab: 1,
            filterPop: false,
            searchTrder: "",
            TimeframeJson: {
                1: 1,
                7: 2,
                30: 3,
                90: 4,
                180: 5,
                365: 6,
                2: 7,
                3: 30,
                4: 90,
                5: 180,
                6: 365,
            },
            page: 0,
            chnageView: "double",
            value: 30,
            showViews: false,
            viewsFilterBy: "",
            viewfilterTimeframeJson: {
                7: 10000,
                8: 30,
                9: 10000,
                10: 10000,
            },
            viewsTextJson: {
                1: this.$t('view_all_leaders.winningtodayLower'),
                2: this.$t('view_all_leaders.winningLastWeek'),
                3: this.$t('view_all_leaders.winningLastMonth'),
                4: this.$t('view_all_leaders.winningLast3month'),
                5: this.$t('view_all_leaders.winningLast6month'),
                6: this.$t('view_all_leaders.winningLastYear'),
                7: this.$t('view_all_leaders.winning'),
                8: this.$t('view_all_leaders.tradingCryptos'),
                9: this.$t('view_all_leaders.allSortbyZuluRank'),
                10: this.$t('view_all_leaders.roi'),
            },
            filterComp: false,
            runningWeeks: {
                left: 0,
                right: 300,
            },
            maxDD: {
                left: 0,
                right: 100,
            },
            slippage: {
                left: 0,
                right: 20,
            },
            minInvestment: {
                left: 0,
                right: 100000,
            },
            avgpips: {
                left: 0,
                right: 1000,
            },
            DDPercent: {
                left: 0,
                right: 100,
            },
            CorPercent: {
                left: 0,
                right: 100,
            },
            winPercent: {
                left: 0,
                right: 100,
            },
            showCountry: false,
            includeCountry: [],
            searchCountry: "",
            showTimeframe: false,
            timeframeJSON: {
                1: this.$t('view_all_leaders.oneDay'),
                7: this.$t('view_all_leaders.sevenDay'),
                30: this.$t('view_all_leaders.oneMonthText'),
                90: this.$t('view_all_leaders.threeMonthText'),
                180: this.$t('view_all_leaders.sixMonths'),
                365: this.$t('view_all_leaders.oneYearText'),
                10000: this.$t('view_all_leaders.overallText'),
            },
            timeframeVal: 30,
            showCurrency: false,
            includeCurrency: [],
            sortBy: "desc",
            SortByJson: {
                rorBasedRoi: this.$t('view_all_leaders.roi'),
                followers: this.$t('view_all_leaders.investorsjson'),
                pips: this.$t('view_all_leaders.pipsJson'),
                trades: this.$t('view_all_leaders.tradesJson'),
                pipsAverage: this.$t('view_all_leaders.avgPipsJson'),
                winTradesPercent: this.$t('view_all_leaders.WinJson'),
                overallDrawDown: this.$t('view_all_leaders.MaxDDpipsJson'),
                weeks: this.$t('view_all_leaders.weeksJson'),
                amountFollowing: this.$t('view_all_leaders.amountFollowingJson'),
                uninterruptedLiveFollowerProfit: this.$t('view_all_leaders.liveInvestorsProfitJson'),
                zuluRank: this.$t('view_all_leaders.zuluRankJson'),
            },
            sortByKey: "uninterruptedLiveFollowerProfit",
            showSortBy: false,
            topHun: false,
            photo: false,
            lastweek: false,
            Investors: false,
            subscribed: false,
            strategy: false,
            exotics: false,
            cryptos: false,
            characterizedJson: {
                hasLiveAccount: false,
                hasDemoAccountAndLiveLinkedToProvider: false,
                partiallyVerified: false,
                hasZuluscripts: false,
                hasApi: false,
                fifo: false,
                ratingStar: false,
                tradingCorrelatedCurrencies: false,
                zuluVeteran: false,
                tradingEconomicEvents: false,
                check: false,
            },
            pips: {
                from: "",
                to: "",
            },
            maxDDPips: {
                from: "",
                to: "",
            },
            worstTrade: {
                from: "",
                to: "",
            },
            bestTrade: {
                from: "",
                to: "",
            },
            openTrades: {
                from: "",
                to: "",
                timeframe: "",
            },
            NME: {
                from: "",
                to: "",
            },
            noOfTrades: {
                from: "",
                to: "",
            },
            Investor: {
                from: "",
                to: "",
            },
            avgTradeTime: {
                from: "",
                to: "",
            },
            compareTrade: "",
            customView: "",
            filterID: '',
            oldFilterBy: "",
            manualSearch: false
        };
    },
    components: {
        topMenu, sharePop
    },
    methods: {
        ROIClass(item) {
            if (parseInt(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) >= 0) {
                return "green";
            } else {
                return "red";
            }
        },
        searchTraders: _.debounce(function () {
            if (this.searchTrder) {
                this.store.callFollowersSearchList({}, false, this.searchTrder);
            }
        }, 500),
        nextUser(userid) {
            if (userid) {
                let path = "/trader/" + userid + "/trading";
                this.$router.replace(path);
                this.searchTrder = "";
            }
        },
        getTrdaerName(id) {
            if (id && this.store.allTradersList.result.length) {
                return this.store.allTradersList.result.filter((val) => parseInt(val.trader.providerId) == parseInt(id))[0].trader.profile.name;
            } else {
                return "";
            }
        },
        removeTrader(id) {
            if (id) {
                let index = this.tradersId.indexOf(id);
                if (index > -1) {
                    this.tradersId.splice(index, 1);
                }
            }
        },
        openFilterPopup(type) {
            this.filterPop = true
            if (type == 'OLD') { this.getLocalStorageFilters() }
        },
        AddCustomView(data, action) {
            if (this.store.user.access_token) {
                this.showLoginPopup = false;
                if (data) {
                    if (this.isFollowed(data.trader.providerId)) {
                        this.addWatchList(data, 'DELETE')
                    } else {
                        this.addWatchList(data, 'ADD')
                    }
                } else {
                    if (action && action == 'EDIT') {
                        this.addCustomView(this.customViewDeatil?.id);
                    } else {
                        this.addCustomView();
                    }
                }
            } else {
                this.showLoginPopup = true;
            }
        },
        addWatchList(item, type) {
            let payload = {
                item: item.trader.providerId,
                type: 'LEADER'
            }
            let json = {};
            if (type == "ADD") {
                json["id"] = item.trader.providerId;
                json["type"] = "LEADER";
                json["name"] = item.trader.profile.name;
                json["roi"] = 0;
                json["chart"] = {};
                let data = this.store.watchList;
                data.push(json);
                this.store.$patch({ watchList: data });
            } else if (type == "DELETE") {
                let data = this.store.watchList.filter((i) => parseInt(i.id) != parseInt(item.trader.providerId));
                this.store.$patch({ watchList: data });
            }
            this.store.addToWatchList(payload, false, this, type)
        },
        callWatchList() {
            this.store.getWatchList({}, false, "180")
        },
        isFollowed(id) {
            if (this.store.watchList.length) {
                let data = this.store.watchList.map(i => i.id)
                if (data.includes(parseInt(id))) {
                    return true
                } else {
                    return false
                }

            } else {
                return false
            }
        },
        addCustomView(editId) {
            let formData = {};
            formData["name"] = this.customView;
            formData["sortAsc"] = this.sortBy == "asc" ? true : false;
            formData["sortExpression"] = this.sortByKey;
            formData["timeFrame"] = parseInt(this.timeframeVal);
            formData["filters"] = [
                {
                    type: "bringDetailedChart",
                    kind: "Boolean",
                    value: "true",
                },
                {
                    type: "accessingFlavorId",
                    kind: "Float",
                    value: "1",
                },
            ];
            //country
            if (this.includeCountry.length) {
                formData["filters"].push({ type: "includeCountryIds", kind: "List", value: JSON.stringify(this.includeCountry) });
            }
            //currency
            if (this.includeCurrency.length) {
                formData["filters"].push({ type: "includeCurrencies", kind: "List", value: JSON.stringify(this.includeCurrency) });
            }
            //checkboxes with text
            if (this.topHun) {
                formData["filters"].push({ type: "top100", kind: "Boolean", value: "true" });
                formData["filters"].push({ type: "maxZuluRank", kind: "Float", value: "100" });
            }
            if (this.photo) {
                formData["filters"].push({ type: "approvedPhotoDescription", kind: "Boolean", value: "true" });
                formData["filters"].push({ type: "approvedDescription", kind: "Boolean", value: "true" });
                formData["filters"].push({ type: "approvedPhoto", kind: "Boolean", value: "true" });
            }
            if (this.lastweek) {
                formData["filters"].push({ type: "tradedWithinLastWeek", kind: "Boolean", value: "true" });
            }
            if (this.Investors) {
                formData["filters"].push({ type: "hasRatings", kind: "Boolean", value: "true" });
            }
            if (this.subscribed) {
                formData["filters"].push({ type: "hasLiveUsersSubscribed", kind: "Boolean", value: "true" });
            }
            if (this.strategy) {
                formData["filters"].push({ type: "approvedVideo", kind: "Boolean", value: "true" });
            }
            if (this.exotics) {
                formData["filters"].push({ type: "tradingExotics", kind: "Boolean", value: "true" });
            }
            if (this.cryptos) {
                formData["filters"].push({ type: "tradingCryptos", kind: "Boolean", value: "true" });
            }

            //characterized
            for (var key in this.characterizedJson) {
                if (this.characterizedJson[key]) {
                    formData["filters"].push({ type: key, kind: "Boolean", value: "true" });
                }
            }
            //sliders
            if (this.runningWeeks.left > 0) {
                formData["filters"].push({ type: "minWeeks", kind: "Float", value: this.runningWeeks.left });
            }
            if (this.runningWeeks.right < 300) {
                formData["filters"].push({ type: "maxWeeks", kind: "Float", value: this.runningWeeks.right });
            }
            if (this.maxDD.left > 0) {
                formData["filters"].push({ type: "minMaxDrawDownPercent", kind: "Float", value: this.maxDD.left });
            }
            if (this.maxDD.right < 100) {
                formData["filters"].push({ type: "maxMaxDrawDownPercent", kind: "Float", value: this.maxDD.right });
            }
            if (this.winPercent.left > 0) {
                formData["filters"].push({ type: "minWinTradesPercentage", kind: "Float", value: this.winPercent.left });
            }
            if (this.winPercent.right < 100) {
                formData["filters"].push({ type: "maxWinTradesPercentage", kind: "Float", value: this.winPercent.right });
            }
            if (this.slippage.left > 0) {
                formData["filters"].push({ type: "minSlippage", kind: "Float", value: this.slippage.left });
            }
            if (this.slippage.right < 20) {
                formData["filters"].push({ type: "maxSlippage", kind: "Float", value: this.slippage.right });
            }
            if (this.minInvestment.left > 0) {
                formData["filters"].push({ type: "minRecommendedMinAmount", kind: "Float", value: this.minInvestment.left });
            }
            if (this.minInvestment.right < 100000) {
                formData["filters"].push({ type: "maxRecommendedMinAmount", kind: "Float", value: this.minInvestment.right });
            }
            if (this.avgpips.left > 0) {
                formData["filters"].push({ type: "minAvgPips", kind: "Float", value: this.avgpips.left });
            }
            if (this.avgpips.right < 1000) {
                formData["filters"].push({ type: "maxAvgPips", kind: "Float", value: this.avgpips.right });
            }
            if (this.DDPercent.left > 0) {
                formData["filters"].push({ type: "minOverallDrawDownMoneyPercent", kind: "Float", value: this.DDPercent.left });
            }
            if (this.DDPercent.right < 100) {
                formData["filters"].push({ type: "maxOverallDrawDownMoneyPercent", kind: "Float", value: this.DDPercent.right });
            }
            if (this.CorPercent.left > 0) {
                formData["filters"].push({ type: "minCorrelationPercent", kind: "Float", value: this.CorPercent.left });
            }
            if (this.CorPercent.right < 100) {
                formData["filters"].push({ type: "maxCorrelationPercent", kind: "Float", value: this.CorPercent.right });
            }
            //Inputs
            if (this.pips.from) {
                formData["filters"].push({ type: "minPips", kind: "String", value: this.pips.from.toString() });
            }
            if (this.pips.to) {
                formData["filters"].push({ type: "maxPips", kind: "String", value: this.pips.to.toString() });
            }
            if (this.maxDDPips.from) {
                formData["filters"].push({ type: "minMaxDrawDownPips", kind: "String", value: this.maxDDPips.from.toString() });
            }
            if (this.maxDDPips.to) {
                formData["filters"].push({ type: "maxMaxDrawDownPips", kind: "String", value: this.maxDDPips.to.toString() });
            }
            if (this.worstTrade.from) {
                formData["filters"].push({ type: "minWorstTradePips", kind: "String", value: this.worstTrade.from.toString() });
            }
            if (this.worstTrade.to) {
                formData["filters"].push({ type: "maxWorstTradePips", kind: "String", value: this.worstTrade.to.toString() });
            }
            if (this.bestTrade.from) {
                formData["filters"].push({ type: "minBestTradePips", kind: "String", value: this.bestTrade.from.toString() });
            }
            if (this.bestTrade.to) {
                formData["filters"].push({ type: "maxBestTradePips", kind: "String", value: this.bestTrade.to.toString() });
            }
            if (this.openTrades.from) {
                formData["filters"].push({ type: "minMaxOpenTrades", kind: "String", value: this.openTrades.from.toString() });
            }
            if (this.openTrades.to) {
                formData["filters"].push({ type: "maxMaxOpenTrades", kind: "String", value: this.openTrades.to.toString() });
            }
            if (this.openTrades.timeframe) {
                formData["filters"].push({ type: "maxOpenTradesTimeFrame", kind: "Float", value: this.openTrades.timeframe.toString() });
            }
            if (this.NME.from) {
                formData["filters"].push({ type: "minNme", kind: "String", value: this.NME.from.toString() });
            }
            if (this.NME.to) {
                formData["filters"].push({ type: "maxNme", kind: "String", value: this.NME.to.toString() });
            }
            if (this.noOfTrades.from) {
                formData["filters"].push({ type: "minTradesCount", kind: "String", value: this.noOfTrades.from.toString() });
            }
            if (this.noOfTrades.to) {
                formData["filters"].push({ type: "maxTradesCount", kind: "String", value: this.noOfTrades.to.toString() });
            }
            if (this.Investor.from) {
                formData["filters"].push({ type: "minFollowersCount", kind: "String", value: this.Investor.from.toString() });
            }
            if (this.Investor.to) {
                formData["filters"].push({ type: "maxFollowersCount", kind: "String", value: this.Investor.to.toString() });
            }
            if (this.avgTradeTime.from) {
                formData["filters"].push({ type: "minAvgTradeTimeSeconds", kind: "String", value: (parseInt(this.avgTradeTime.from) * 3600).toString() });
            }
            if (this.avgTradeTime.to) {
                formData["filters"].push({ type: "maxAvgTradeTimeSeconds", kind: "String", value: (parseInt(this.avgTradeTime.to) * 3600).toString() });
            }
            if (this.compareTrade == "gt") {
                formData["filters"].push({ type: "bestTradeGreaterThanWorstTrade", kind: "Boolean", value: "true" });
            }
            if (this.compareTrade == "lt") {
                formData["filters"].push({ type: "bestTradeGreaterThanWorstTrade", kind: "Boolean", value: "false" });
            }
            if (this.customView?.length > 4) {
                this.store.callAddtraderCustomView(formData, false, this, editId).then(res => {
                    if (res) { this.getCustomViewList() }
                })
                this.filterID = ''
                this.getAllTradersList()
            } else if(this.store.user.access_token){
                this.toast.error(this.$t('view_all_leaders.nameToast'));
            }
        },
        drawprofitChart(id, chartData) {
            let ID = "allTrader" + id;
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === ID);
            d?.dispose();
            let root = am5.Root.new(ID);

            root.interfaceColors.set("grid", am5.color(0xffffff));

            root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
            root.numberFormatter.setAll({
                numberFormat: "#a",
                smallNumberThreshold: 0.001,
            });

            var chart = root.container.children.push(am5xy.XYChart.new(root, { focusable: true }));

            // Create axes
            let xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    groupData: false,
                    baseInterval: {
                        timeUnit: "day",
                        count: 1,
                    },
                    renderer: am5xy.AxisRendererX.new(root, {}),
                })
            );

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {}),
                })
            );
            let yRenderer = yAxis.get("renderer");
            yRenderer.grid.template.setAll({
                stroke: am5.color(0x666666),
                strokeWidth: 1,
            });

            chart.get("colors").set("colors", [am5.color(0xffa65e)]);
            // Add series
            var series = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    minBulletDistance: 10,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "y",
                    valueXField: "x",
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "{valueX.formatDate('dd MMM yyyy')}: {valueY}",
                    }),
                })
            );
            series.fills.template.setAll({
                visible: true,
            });

            series.fills.template.set(
                "fillGradient",
                am5.LinearGradient.new(root, {
                    stops: [
                        {
                            opacity: 0.2,
                        },
                        {
                            opacity: 0.2,
                        },
                    ],
                })
            );

            series.strokes.template.setAll({
                strokeWidth: 2,
            });

            // Set up data processor to parse string dates
            series.data.processor = am5.DataProcessor.new(root, {
                dateFormat: "yyyy-MM-dd",
                dateFields: ["y"],
            });
            // Add cursor
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);
            cursor.lineX.set("visible", false);
            xAxis.hide();

            yAxis.get("renderer").labels.template.setAll({
                fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                fontSize: 12,
            });
            // Set data
            series.data.setAll(chartData);

            // Make stuff animate on load
            series.appear(300);
            chart.appear(300, 100);
        },
        loadMore() {
            if (!this.store.loading) {
                this.page += 1;
                this.getAllTradersList();
            }
        },
        changeTab(val) {
            this.filterID = '';
            this.tab = val;
            this.viewsFilterBy = val;
            this.page = 0;
            this.customView = this.viewsTextJson[this.viewsFilterBy];
            this.clearAll('slider')
            this.getAllTradersList();
        },
        getAllTradersList(data) {
            let formData = {
                accessingFlavorId: this.store.flavordata?.flavorId || 1,
                backendid: parseInt(this.viewsFilterBy) > 6 ? parseInt(this.viewsFilterBy) : parseInt(this.tab),
                bringDetailedChart: true,
                flavor: this.store.flavordata?.isEu ? 'eu' : 'global',
                page: this.page,
                size: 50,
                sortAsc: false,
                sortBy: this.filterPop ? this.sortByKey : "uninterruptedLiveFollowerProfit",
                timeFrame: this.filterPop ? parseInt(this.timeframeVal) : parseInt(this.viewsFilterBy) > 6 ? this.viewfilterTimeframeJson[parseInt(this.viewsFilterBy)] : this.TimeframeJson[parseInt(this.tab)],
            };
            if (parseInt(this.viewsFilterBy) > 6) {
                if (parseInt(this.viewsFilterBy) == 8) {
                    formData["includeCurrencies"] = ["BTCUSD", "ETHUSD", "LTCUSD", "BCHUSD", "XRPUSD"];
                    formData['tradingCryptos'] = true;
                }
                if (parseInt(this.viewsFilterBy) == 9) {
                    formData["sortBy"] = "zuluRank";
                    formData["sortAsc"] = true
                }
                if (parseInt(this.viewsFilterBy) == 10) {
                    formData["sortBy"] = "rorBasedRoi";
                }
            } else if (this.store.user.access_token && data && Object.keys(data).length && data.filterParams) { //when we select custom view
                this.clearAll('slider')
                this.isCustomView = true;
                let params = JSON.parse(data.filterParams);
                formData["sortBy"] = params.sortExpression;
                formData["timeFrame"] = params.timeFrame;
                formData["sortAsc"] = params.sortDirection == "Ascending" ? true : false;
                this.sortByKey = params.sortExpression;
                this.timeframeVal = params.timeFrame;
                this.sortBy = params.sortDirection == "Ascending" ? true : false;
                if (params.filters && params.filters.length) {
                    params.filters.forEach((item) => {
                        //country
                        if (item.type == "includeCountryIds") {
                            this.includeCountry = JSON.parse(item.value);
                        } 
                        // else { this.includeCountry = [] }
                        //currency
                        if (item.type == "includeCurrencies") {
                            this.includeCurrency = JSON.parse(item.value);
                        } 
                        // else { this.includeCurrency = [] }
                        //checkboxes with text
                        if (item.type == "maxZuluRank" || item.type == "top100") {
                            this.topHun = true;
                        }
                        if (item.type == "approvedDescription" || item.type == "approvedPhotoDescription" || item.type == "approvedPhoto") {
                            this.photo = true;
                        }
                        if (item.type == "tradedWithinLastWeek") {
                            this.lastweek = true;
                        }
                        if (item.type == "hasRatings") {
                            this.Investors = true;
                        }
                        if (item.type == "hasLiveUsersSubscribed") {
                            this.subscribed = true;
                        }
                        if (item.type == "approvedVideo") {
                            this.strategy = true;
                        }
                        if (item.type == "tradingExotics") {
                            this.exotics = true;
                        }
                        if (item.type == "tradingCryptos") {
                            this.cryptos = true;
                        }
                        //characterised
                        if (item.type == "hasLiveAccount") {
                            this.characterizedJson.hasLiveAccount = true;
                        }
                        if (item.type == "hasDemoAccountAndLiveLinkedToProvider") {
                            this.characterizedJson.hasDemoAccountAndLiveLinkedToProvider = true;
                        }
                        if (item.type == "partiallyVerified") {
                            this.characterizedJson.partiallyVerified = true;
                        }
                        if (item.type == "hasZuluscripts") {
                            this.characterizedJson.hasZuluscripts = true;
                        }
                        if (item.type == "hasApi") {
                            this.characterizedJson.hasApi = true;
                        }
                        if (item.type == "fifo") {
                            this.characterizedJson.fifo = true;
                        }
                        if (item.type == "tradingCorrelatedCurrencies") {
                            this.characterizedJson.tradingCorrelatedCurrencies = true;
                        }
                        if (item.type == "zuluVeteran") {
                            this.characterizedJson.zuluVeteran = true;
                        }
                        if (item.type == "tradingEconomicEvents") {
                            this.characterizedJson.tradingEconomicEvents = true;
                        }
                        if (item.type == "check") {
                            this.characterizedJson.check = true;
                        }
                        if (item.type == "ratingStar") {
                            this.characterizedJson.ratingStar = true;
                        }
                        //slider
                        if (item.type == "minWeeks") {
                            this.runningWeeks.left = parseInt(item.value);
                        }
                        if (item.type == "maxWeeks") {
                            this.runningWeeks.right = parseInt(item.value);
                        }
                        if (item.type == "minMaxDrawDownPercent") {
                            this.maxDD.left = parseInt(item.value);
                        }
                        if (item.type == "maxMaxDrawDownPercent") {
                            this.maxDD.right = parseInt(item.value);
                        }
                        if (item.type == "minWinTradesPercentage") {
                            this.winPercent.left = parseInt(item.value);
                        }
                        if (item.type == "maxWinTradesPercentage") {
                            this.winPercent.right = parseInt(item.value);
                        }
                        if (item.type == "minSlippage") {
                            this.slippage.left = parseInt(item.value);
                        }
                        if (item.type == "maxSlippage") {
                            this.slippage.right = parseInt(item.value);
                        }
                        if (item.type == "minRecommendedMinAmount") {
                            this.minInvestment.left = parseInt(item.value);
                        }
                        if (item.type == "maxRecommendedMinAmount") {
                            this.minInvestment.right = parseInt(item.value);
                        }
                        if (item.type == "minAvgPips") {
                            this.avgpips.left = parseInt(item.value);
                        }
                        if (item.type == "maxAvgPips") {
                            this.avgpips.right = parseInt(item.value);
                        }
                        if (item.type == "minOverallDrawDownMoneyPercent") {
                            this.DDPercent.left = parseInt(item.value);
                        }
                        if (item.type == "maxOverallDrawDownMoneyPercent") {
                            this.DDPercent.right = parseInt(item.value);
                        }
                        if (item.type == "minCorrelationPercent") {
                            this.CorPercent.left = parseInt(item.value);
                        }
                        if (item.type == "maxCorrelationPercent") {
                            this.CorPercent.right = parseInt(item.value);
                        }
                        //Inputs
                        if (item.type == "minPips") {
                            this.pips.from = parseInt(item.value);
                        }
                        if (item.type == "maxPips") {
                            this.pips.to = parseInt(item.value);
                        }
                        if (item.type == "minMaxDrawDownPips") {
                            this.maxDDPips.from = parseInt(item.value);
                        }
                        if (item.type == "maxMaxDrawDownPips") {
                            this.maxDDPips.to = parseInt(item.value);
                        }
                        if (item.type == "minWorstTradePips") {
                            this.worstTrade.from = parseInt(item.value);
                        }
                        if (item.type == "maxWorstTradePips") {
                            this.worstTrade.to = parseInt(item.value);
                        }
                        if (item.type == "minBestTradePips") {
                            this.bestTrade.from = parseInt(item.value);
                        }
                        if (item.type == "maxBestTradePips") {
                            this.bestTrade.to = parseInt(item.value);
                        }
                        if (item.type == "minMaxOpenTrades") {
                            this.openTrades.from = parseInt(item.value);
                        }
                        if (item.type == "maxMaxOpenTrades") {
                            this.openTrades.to = parseInt(item.value);
                        }
                        if (item.type == "maxOpenTradesTimeFrame") {
                            this.openTrades.timeframe = parseInt(item.value);
                        }
                        if (item.type == "minNme") {
                            this.NME.from = parseInt(item.value);
                        }
                        if (item.type == "maxNme") {
                            this.NME.to = parseInt(item.value);
                        }
                        if (item.type == "minTradesCount") {
                            this.noOfTrades.from = parseInt(item.value);
                        }
                        if (item.type == "maxTradesCount") {
                            this.noOfTrades.to = parseInt(item.value);
                        }
                        if (item.type == "minFollowersCount") {
                            this.Investor.from = parseInt(item.value);
                        }
                        if (item.type == "maxFollowersCount") {
                            this.Investor.to = parseInt(item.value);
                        }
                        if (item.type == "minAvgTradeTimeSeconds") {
                            this.avgTradeTime.from = parseInt(item.value) / 3600;
                        }
                        if (item.type == "maxAvgTradeTimeSeconds") {
                            this.avgTradeTime.to = parseInt(item.value) / 3600;
                        }
                        if (item.type == "bestTradeGreaterThanWorstTrade") {
                            this.compareTrade = JSON.parse(item.value) == false ? "lt" : "gt";
                        }
                    });
                }
            } else {
                this.isCustomView = false;
            }
            if (this.filterPop) {
                formData["backendid"] = null;
                formData["sortAsc"] = this.sortBy == "asc" ? true : false;
            }
            //country
            if (this.includeCountry.length) {
                formData["includeCountryIds"] = this.includeCountry;
            }
            //currency
            if (this.includeCurrency.length) {
                formData["includeCurrencies"] = this.includeCurrency;
            }
            //checkboxes with text
            if (this.topHun) {
                formData["maxZuluRank"] = 100;
                formData["top100"] = true;
            }
            if (this.photo) {
                formData["approvedDescription"] = true;
                formData["approvedPhotoDescription"] = true;
                formData["approvedPhoto"] = true;
            }
            if (this.lastweek) {
                formData["tradedWithinLastWeek"] = true;
            }
            if (this.Investors) {
                formData["hasRatings"] = true;
            }
            if (this.subscribed) {
                formData["hasLiveUsersSubscribed"] = true;
            }
            if (this.strategy) {
                formData["approvedVideo"] = true;
            }
            if (this.exotics) {
                formData["tradingExotics"] = true;
            }
            if (this.cryptos) {
                formData["tradingCryptos"] = true;
            }
            //characterized
            for (var key in this.characterizedJson) {
                if (this.characterizedJson[key]) {
                    formData[key] = true;
                }
            }
            //sliders
            if (this.runningWeeks.left > 0) {
                formData["minWeeks"] = this.runningWeeks.left;
            }
            if (this.runningWeeks.right < 300) {
                formData["maxWeeks"] = this.runningWeeks.right;
            }
            if (this.maxDD.left > 0) {
                formData["minMaxDrawDownPercent"] = this.maxDD.left;
            }
            if (this.maxDD.right < 100) {
                formData["maxMaxDrawDownPercent"] = this.maxDD.right;
            }
            if (this.winPercent.left > 0) {
                formData["minWinTradesPercentage"] = this.winPercent.left;
            }
            if (this.winPercent.right < 100) {
                formData["maxWinTradesPercentage"] = this.winPercent.right;
            }
            if (this.slippage.left > 0) {
                formData["minSlippage"] = this.slippage.left;
            }
            if (this.slippage.right < 20) {
                formData["maxSlippage"] = this.slippage.right;
            }
            if (this.minInvestment.left > 0) {
                formData["minRecommendedMinAmount"] = this.minInvestment.left;
            }
            if (this.minInvestment.right < 100000) {
                formData["maxRecommendedMinAmount"] = this.minInvestment.right;
            }
            if (this.avgpips.left > 0) {
                formData["minAvgPips"] = this.avgpips.left;
            }
            if (this.avgpips.right < 1000) {
                formData["maxAvgPips"] = this.avgpips.right;
            }
            if (this.DDPercent.left > 0) {
                formData["minOverallDrawDownMoneyPercent"] = this.DDPercent.left;
            }
            if (this.DDPercent.right < 100) {
                formData["maxOverallDrawDownMoneyPercent"] = this.DDPercent.right;
            }
            if (this.CorPercent.left > 0) {
                formData["minCorrelationPercent"] = this.CorPercent.left;
            }
            if (this.CorPercent.right < 100) {
                formData["maxCorrelationPercent"] = this.CorPercent.right;
            }
            //Inputs
            if (this.pips.from) {
                formData["minPips"] = this.pips.from;
            }
            if (this.pips.to) {
                formData["maxPips"] = this.pips.to;
            }
            if (this.maxDDPips.from) {
                formData["minMaxDrawDownPips"] = this.maxDDPips.from;
            }
            if (this.maxDDPips.to) {
                formData["maxMaxDrawDownPips"] = this.maxDDPips.to;
            }
            if (this.worstTrade.from) {
                formData["minWorstTradePips"] = this.worstTrade.from;
            }
            if (this.worstTrade.to) {
                formData["maxWorstTradePips"] = this.worstTrade.to;
            }
            if (this.bestTrade.from) {
                formData["minBestTradePips"] = this.bestTrade.from;
            }
            if (this.bestTrade.to) {
                formData["maxBestTradePips"] = this.bestTrade.to;
            }
            if (this.openTrades.from) {
                formData["minMaxOpenTrades"] = this.openTrades.from;
            }
            if (this.openTrades.to) {
                formData["maxMaxOpenTrades"] = this.openTrades.to;
            }
            if (this.openTrades.timeframe) {
                formData["maxOpenTradesTimeFrame"] = this.openTrades.timeframe;
            }
            if (this.NME.from) {
                formData["minNme"] = this.NME.from;
            }
            if (this.NME.to) {
                formData["maxNme"] = this.NME.to;
            }
            if (this.noOfTrades.from) {
                formData["minTradesCount"] = this.noOfTrades.from;
            }
            if (this.noOfTrades.to) {
                formData["maxTradesCount"] = this.noOfTrades.to;
            }
            if (this.Investor.from) {
                formData["minFollowersCount"] = this.Investor.from;
            }
            if (this.Investor.to) {
                formData["maxFollowersCount"] = this.Investor.to;
            }
            if (this.avgTradeTime.from) {
                formData["minAvgTradeTimeSeconds"] = parseInt(this.avgTradeTime.from) * 3600;
            }
            if (this.avgTradeTime.to) {
                formData["maxAvgTradeTimeSeconds"] = parseInt(this.avgTradeTime.to) * 3600;
            }
            if (this.compareTrade == "gt") {
                formData["bestTradeGreaterThanWorstTrade"] = true;
            }
            if (this.compareTrade == "lt") {
                formData["bestTradeGreaterThanWorstTrade"] = false;
            }


            

            if (this.filterID) {
                this.store.callfilteredTradersList({}, true, this.filterID, this).then(() => {
                    this.filterPop = false;
                    if (this.chnageView == "triple") {
                        this.drawChart();
                    }
                })
            } else {
                let payload = {}
                if(!this.store.user.access_token && data && Object.keys(data).length){
                    payload = data.filterParams
                    this.isCustomView = true
                } else {
                    payload = formData
                }
                if(formData?.tradingCryptos){
                    payload['instrumentTypes'] = ["CRYPTOS_CFD", "CRYPTOS_SPOT"];
                    delete formData?.tradingCryptos;
                }
                
                this.store.callAllTradersList(payload, true, this.viewsFilterBy, this).then(() => {
                    this.filterPop = false;
                    if (this.chnageView == "triple") {
                        this.drawChart();
                    }
                });
            }
        },
        drawChart() {
            this.filterPop = false;
            setTimeout(() => {
                if (Object.keys(this.store.allTradersList).length && this.store.allTradersList.result && this.store.allTradersList.result.length) {
                    this.store.allTradersList.result.forEach((val) => {
                        if (
                            val.trader &&
                            Object.keys(val.trader).length &&
                            val.trader.timeFrameGraph &&
                            val.trader.timeFrameGraph.series &&
                            val.trader.timeFrameGraph.series.length &&
                            val.trader.timeFrameGraph.series[0].data &&
                            val.trader.timeFrameGraph.series[0].data.length
                        ) {
                            this.drawprofitChart(val.trader.providerId, val.trader.timeFrameGraph.series[0].data);
                        }
                    });
                }
            }, 500);
        },
        leftSlider(e, ref) {
            let target = e.target;
            target.value = Math.min(target.value, target.parentNode.childNodes[2].value - 1);
            var value = (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(target.value) - (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(target.min);
            var children = target.parentNode.childNodes[0].childNodes;
            children[0].style.width = value + "%";
            children[2].style.left = value + "%";
            children[3].style.left = value + "%";
            children[5].style.left = value + "%";
            children[5].childNodes[0].innerHTML = target.value;
            this[ref].left = parseInt(target.value);
        },
        rightSlider(e, ref) {
            let target = e.target;
            target.value = Math.max(target.value, target.parentNode.childNodes[1].value - -1);
            var value = (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(target.value) - (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(target.min);
            var children = target.parentNode.childNodes[0].childNodes;
            children[1].style.width = 100 - value + "%";
            children[2].style.right = 100 - value + "%";
            children[4].style.left = value + "%";
            children[6].style.left = value + "%";
            children[6].childNodes[0].innerHTML = target.value;
            this[ref].right = parseInt(target.value);
        },
        changeSlider(e, ref, type, model) {
            let target = this.$refs[ref];
            let inputVal = e.target.value;
            if (type == "LEFT") {
                if (parseInt(inputVal) <= parseInt(target.max)) {
                    target.value = Math.min(inputVal, target.parentNode.childNodes[2].value - 1);
                    var value = (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(inputVal) - (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(target.min);
                    var children = target.parentNode.childNodes[0].childNodes;
                    children[0].style.width = value + "%";
                    children[2].style.left = value + "%";
                    children[3].style.left = value + "%";
                    children[5].style.left = value + "%";
                    children[5].childNodes[0].innerHTML = inputVal;
                }
            } else if (type == "RIGHT") {
                var value1 = "";
                if (parseInt(inputVal) <= parseInt(target.max)) {
                    if (parseInt(inputVal) <= parseInt(this[model].left)) {
                        //if max is less than min
                        target.value = parseInt(this[model].left) + 1;
                        inputVal = parseInt(this[model].left) + 1;
                        value1 = (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(inputVal) - (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(target.min);
                    } else {
                        target.value = Math.max(inputVal, target.parentNode.childNodes[1].value - -1);
                    }
                    value1 = (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(inputVal) - (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(target.min);
                    var children1 = target.parentNode.childNodes[0].childNodes;
                    children1[1].style.width = 100 - value1 + "%";
                    children1[2].style.right = 100 - value1 + "%";
                    children1[4].style.left = value1 + "%";
                    children1[6].style.left = value1 + "%";
                    children1[6].childNodes[0].innerHTML = inputVal;
                }
            }
        },
        filterCurrency(item) {
            if (item) {
                if (this.includeCurrency.includes(item)) {
                    let index = this.includeCurrency.indexOf(item);
                    if (index > -1) {
                        this.includeCurrency.splice(index, 1);
                    }
                } else {
                    this.includeCurrency.push(item);
                }
            }
        },
        filterCountry(item) {
            if (Object.keys(item).length) {
                if (this.includeCountry.includes(parseInt(item.countryId))) {
                    let index = this.includeCountry.indexOf(item.countryId);
                    if (index > -1) {
                        this.includeCountry.splice(index, 1);
                    }
                } else {
                    this.includeCountry.push(item.countryId);
                }
            }
        },
        resetSlider(value, ref, type) {
            let target = this.$refs[ref];
            let inputVal = value;
            if (type == "LEFT") {
                target.value = Math.min(inputVal, target.parentNode.childNodes[2].value - 1);
                var val = (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(inputVal) - (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(target.min);
                var children = target.parentNode.childNodes[0].childNodes;
                children[0].style.width = val + "%";
                children[2].style.left = val + "%";
                children[3].style.left = val + "%";
                children[5].style.left = val + "%";
                children[5].childNodes[0].innerHTML = inputVal;
            } else if (type == "RIGHT") {
                if (parseInt(inputVal) <= parseInt(target.max)) {
                    target.value = Math.max(inputVal, target.parentNode.childNodes[1].value - -1);
                    var value1 = (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(inputVal) - (100 / (parseInt(target.max) - parseInt(target.min))) * parseInt(target.min);
                    var children1 = target.parentNode.childNodes[0].childNodes;
                    children1[1].style.width = 100 - value1 + "%";
                    children1[2].style.right = 100 - value1 + "%";
                    children1[4].style.left = value1 + "%";
                    children1[6].style.left = value1 + "%";
                    children1[6].childNodes[0].innerHTML = inputVal;
                }
            }
        },
        setSlidersWithData() {
            this.resetSlider(this.runningWeeks.left, "left1", "LEFT");
            this.resetSlider(this.runningWeeks.right, "right1", "RIGHT");
            this.resetSlider(this.maxDD.left, "left2", "LEFT");
            this.resetSlider(this.maxDD.right, "right2", "RIGHT");
            this.resetSlider(this.slippage.left, "left3", "LEFT");
            this.resetSlider(this.slippage.right, "right3", "RIGHT");
            this.resetSlider(this.minInvestment.left, "left4", "LEFT");
            this.resetSlider(this.minInvestment.right, "right4", "RIGHT");
            this.resetSlider(this.avgpips.left, "left5", "LEFT");
            this.resetSlider(this.avgpips.right, "right5", "RIGHT");
            this.resetSlider(this.DDPercent.left, "left6", "LEFT");
            this.resetSlider(this.DDPercent.right, "right6", "RIGHT");
            this.resetSlider(this.CorPercent.left, "left7", "LEFT");
            this.resetSlider(this.CorPercent.right, "right7", "RIGHT");
            this.resetSlider(this.winPercent.left, "left8", "LEFT");
            this.resetSlider(this.winPercent.right, "right8", "RIGHT");
        },
        clearAll(from,type) {
            this.runningWeeks = {
                left: 0,
                right: 300,
            };
            if (from && from != "noslider") {
                this.resetSlider(this.runningWeeks.left, "left1", "LEFT");
                this.resetSlider(this.runningWeeks.right, "right1", "RIGHT");
            }
            this.maxDD = {
                left: 0,
                right: 100,
            };
            if (from && from != "noslider") {
                this.resetSlider(this.maxDD.left, "left2", "LEFT");
                this.resetSlider(this.maxDD.right, "right2", "RIGHT");
            }
            this.slippage = {
                left: 0,
                right: 20,
            };
            if (from && from != "noslider") {
                this.resetSlider(this.slippage.left, "left3", "LEFT");
                this.resetSlider(this.slippage.right, "right3", "RIGHT");
            }
            this.minInvestment = {
                left: 0,
                right: 100000,
            };
            if (from && from != "noslider") {
                this.resetSlider(this.minInvestment.left, "left4", "LEFT");
                this.resetSlider(this.minInvestment.right, "right4", "RIGHT");
            }
            this.avgpips = {
                left: 0,
                right: 1000,
            };
            if (from && from != "noslider") {
                this.resetSlider(this.avgpips.left, "left5", "LEFT");
                this.resetSlider(this.avgpips.right, "right5", "RIGHT");
            }
            this.DDPercent = {
                left: 0,
                right: 100,
            };
            if (from && from != "noslider") {
                this.resetSlider(this.DDPercent.left, "left6", "LEFT");
                this.resetSlider(this.DDPercent.right, "right6", "RIGHT");
            }
            this.CorPercent = {
                left: 0,
                right: 100,
            };
            if (from && from != "noslider") {
                this.resetSlider(this.CorPercent.left, "left7", "LEFT");
                this.resetSlider(this.CorPercent.right, "right7", "RIGHT");
            }
            this.winPercent = {
                left: 0,
                right: 100,
            };
            if (from && from != "noslider") {
                this.resetSlider(this.winPercent.left, "left8", "LEFT");
                this.resetSlider(this.winPercent.right, "right8", "RIGHT");
            }
            this.showCountry = false;
            this.includeCountry = [];
            this.searchCountry = "";
            this.showTimeframe = false;
            this.timeframeVal = 30;
            this.showCurrency = false;
            this.includeCurrency = [];
            this.sortBy = "desc";
            this.sortByKey = "uninterruptedLiveFollowerProfit";
            this.showSortBy = false;
            this.topHun = false;
            this.photo = false;
            this.lastweek = false;
            this.Investors = false;
            this.subscribed = false;
            this.strategy = false;
            this.exotics = false;
            this.cryptos = false;
            this.characterizedJson = {
                hasLiveAccount: false,
                hasDemoAccountAndLiveLinkedToProvider: false,
                partiallyVerified: false,
                hasZuluscripts: false,
                hasApi: false,
                fifo: false,
                ratingStar: false,
                tradingCorrelatedCurrencies: false,
                zuluVeteran: false,
                tradingEconomicEvents: false,
                check: false,
            };
            this.pips = {
                from: "",
                to: "",
            };
            this.maxDDPips = {
                from: "",
                to: "",
            };
            this.worstTrade = {
                from: "",
                to: "",
            };
            this.bestTrade = {
                from: "",
                to: "",
            };
            this.openTrades = {
                from: "",
                to: "",
                timeframe: "",
            };
            this.NME = {
                from: "",
                to: "",
            };
            this.noOfTrades = {
                from: "",
                to: "",
            };
            this.Investor = {
                from: "",
                to: "",
            };
            this.avgTradeTime = {
                from: "",
                to: "",
            };
            this.compareTrade = "";
            if(type == 'delete'){ this.resetFiltersLocalStorage(); }
        },
        getCustomViewList() {
            this.store.callCustomViewList({}, false, "list");
        },
        getCustomList(){
            if(this.store.user.access_token){
                return this.store.customViewList
            } else {
                let l_store_filters = localStorage.getItem("leader_filters");
                    l_store_filters = JSON.parse(l_store_filters);
                return l_store_filters || []
            }
        },
        deleteCustomView() {
            if (Object.keys(this.customViewDeatil).length) {
                this.store.callCustomViewList({}, false, "delete", this.customViewDeatil.id).then(() => {
                    this.filterPop = false;
                    this.viewsFilterBy = 3;
                    this.customView = this.viewsTextJson[this.viewsFilterBy];
                    this.customViewDeatil = {};
                    this.getAllTradersList();
                });
            }
        },
        openFilterTab(value) {
            this.oldFilterBy = this.viewsFilterBy;
            this.filterID = '';
            this.viewsFilterBy = value;
            this.customViewDeatil = {};
            this.clearAll('slider')
            this.customView = this.viewsTextJson[this.viewsFilterBy];

            // if(this.oldFilterBy != this.viewsFilterBy){
            //     this.clearAll("slider");
            // }
            this.getAllTradersList()
        },
        getLocalStorageFilters() {
            let l_store_filters = localStorage.getItem("leader_filters");
            l_store_filters = JSON.parse(l_store_filters);
            if (l_store_filters?.length) {
                let find_value = l_store_filters.find(arr => arr.name == this.customView);
                if (find_value) {
                    for (let key in find_value['filterParams']) {
                        if (key in this.$data) {
                            this.$data[key] = find_value['filterParams'][key];
                        }
                    }
                }

            }
            this.setSlidersWithData();
        },
        resetFiltersLocalStorage() {
            var l_store_filters = localStorage.getItem("leader_filters") || [];
            if (l_store_filters?.length && this.customView) {
                l_store_filters = JSON.parse(l_store_filters)
                let filtered_value = l_store_filters.filter(arr => arr.name != this.customView);
                filtered_value = JSON.stringify(filtered_value)
                localStorage.setItem("leader_filters", filtered_value);
            }
        },
        setLocalStorageFilters(location) {
            this.manualSearch = true;
                let formData = {
                    accessingFlavorId: this.store.flavordata?.flavorId || 1,
                    backendid: parseInt(this.viewsFilterBy) > 6 ? parseInt(this.viewsFilterBy) : parseInt(this.tab),
                    bringDetailedChart: true,
                    flavor: this.store.flavordata?.isEu ? 'eu' : 'global',
                    page: this.page,
                    size: 50,
                    sortAsc: false,
                    sortBy: this.filterPop ? this.sortByKey : "uninterruptedLiveFollowerProfit",
                    timeFrame: this.filterPop ? parseInt(this.timeframeVal) : parseInt(this.viewsFilterBy) > 6 ? this.viewfilterTimeframeJson[parseInt(this.viewsFilterBy)] : this.TimeframeJson[parseInt(this.tab)],
                };
                if (this.filterPop) {
                    formData["backendid"] = null;
                    formData["sortAsc"] = this.sortBy == "asc" ? true : false;
                }
                //country
                if (this.includeCountry.length) {
                    formData["includeCountryIds"] = this.includeCountry;
                }
                //currency
                if (this.includeCurrency.length) {
                    formData["includeCurrencies"] = this.includeCurrency;
                }
                //checkboxes with text
                if (this.topHun) {
                    formData["maxZuluRank"] = 100;
                    formData["top100"] = true;
                }
                if (this.photo) {
                    formData["approvedDescription"] = true;
                    formData["approvedPhotoDescription"] = true;
                    formData["approvedPhoto"] = true;
                }
                if (this.lastweek) {
                    formData["tradedWithinLastWeek"] = true;
                }
                if (this.Investors) {
                    formData["hasRatings"] = true;
                }
                if (this.subscribed) {
                    formData["hasLiveUsersSubscribed"] = true;
                }
                if (this.strategy) {
                    formData["approvedVideo"] = true;
                }
                if (this.exotics) {
                    formData["tradingExotics"] = true;
                }
                if (this.cryptos) {
                    formData["tradingCryptos"] = true;
                }
                //characterized
                for (var key in this.characterizedJson) {
                    if (this.characterizedJson[key]) {
                        formData[key] = true;
                    }
                }
                //sliders
                if (this.runningWeeks.left > 0) {
                    formData["minWeeks"] = this.runningWeeks.left;
                }
                if (this.runningWeeks.right < 300) {
                    formData["maxWeeks"] = this.runningWeeks.right;
                }
                if (this.maxDD.left > 0) {
                    formData["minMaxDrawDownPercent"] = this.maxDD.left;
                }
                if (this.maxDD.right < 100) {
                    formData["maxMaxDrawDownPercent"] = this.maxDD.right;
                }
                if (this.winPercent.left > 0) {
                    formData["minWinTradesPercentage"] = this.winPercent.left;
                }
                if (this.winPercent.right < 100) {
                    formData["maxWinTradesPercentage"] = this.winPercent.right;
                }
                if (this.slippage.left > 0) {
                    formData["minSlippage"] = this.slippage.left;
                }
                if (this.slippage.right < 20) {
                    formData["maxSlippage"] = this.slippage.right;
                }
                if (this.minInvestment.left > 0) {
                    formData["minRecommendedMinAmount"] = this.minInvestment.left;
                }
                if (this.minInvestment.right < 100000) {
                    formData["maxRecommendedMinAmount"] = this.minInvestment.right;
                }
                if (this.avgpips.left > 0) {
                    formData["minAvgPips"] = this.avgpips.left;
                }
                if (this.avgpips.right < 1000) {
                    formData["maxAvgPips"] = this.avgpips.right;
                }
                if (this.DDPercent.left > 0) {
                    formData["minOverallDrawDownMoneyPercent"] = this.DDPercent.left;
                }
                if (this.DDPercent.right < 100) {
                    formData["maxOverallDrawDownMoneyPercent"] = this.DDPercent.right;
                }
                if (this.CorPercent.left > 0) {
                    formData["minCorrelationPercent"] = this.CorPercent.left;
                }
                if (this.CorPercent.right < 100) {
                    formData["maxCorrelationPercent"] = this.CorPercent.right;
                }
                //Inputs
                if (this.pips.from) {
                    formData["minPips"] = this.pips.from;
                }
                if (this.pips.to) {
                    formData["maxPips"] = this.pips.to;
                }
                if (this.maxDDPips.from) {
                    formData["minMaxDrawDownPips"] = this.maxDDPips.from;
                }
                if (this.maxDDPips.to) {
                    formData["maxMaxDrawDownPips"] = this.maxDDPips.to;
                }
                if (this.worstTrade.from) {
                    formData["minWorstTradePips"] = this.worstTrade.from;
                }
                if (this.worstTrade.to) {
                    formData["maxWorstTradePips"] = this.worstTrade.to;
                }
                if (this.bestTrade.from) {
                    formData["minBestTradePips"] = this.bestTrade.from;
                }
                if (this.bestTrade.to) {
                    formData["maxBestTradePips"] = this.bestTrade.to;
                }
                if (this.openTrades.from) {
                    formData["minMaxOpenTrades"] = this.openTrades.from;
                }
                if (this.openTrades.to) {
                    formData["maxMaxOpenTrades"] = this.openTrades.to;
                }
                if (this.openTrades.timeframe) {
                    formData["maxOpenTradesTimeFrame"] = this.openTrades.timeframe;
                }
                if (this.NME.from) {
                    formData["minNme"] = this.NME.from;
                }
                if (this.NME.to) {
                    formData["maxNme"] = this.NME.to;
                }
                if (this.noOfTrades.from) {
                    formData["minTradesCount"] = this.noOfTrades.from;
                }
                if (this.noOfTrades.to) {
                    formData["maxTradesCount"] = this.noOfTrades.to;
                }
                if (this.Investor.from) {
                    formData["minFollowersCount"] = this.Investor.from;
                }
                if (this.Investor.to) {
                    formData["maxFollowersCount"] = this.Investor.to;
                }
                if (this.avgTradeTime.from) {
                    formData["minAvgTradeTimeSeconds"] = parseInt(this.avgTradeTime.from) * 3600;
                }
                if (this.avgTradeTime.to) {
                    formData["maxAvgTradeTimeSeconds"] = parseInt(this.avgTradeTime.to) * 3600;
                }
                if (this.compareTrade == "gt") {
                    formData["bestTradeGreaterThanWorstTrade"] = true;
                }
                if (this.compareTrade == "lt") {
                    formData["bestTradeGreaterThanWorstTrade"] = false;
                }
            let filter_data = { 'name': this.customView, 'filterParams': formData };
            let l_store_filters = localStorage.getItem("leader_filters");
            if (l_store_filters) {
                l_store_filters = JSON.parse(l_store_filters);
                let c_index = l_store_filters.findIndex(arr => arr.name == this.customView);
                if (c_index > -1) {
                    l_store_filters.splice(c_index, 1, filter_data);
                }
                else {
                    l_store_filters.push(filter_data);
                }
            }
            else {
                l_store_filters = [filter_data]
            }
            if (this.customView?.length > 4) {
                localStorage.setItem("leader_filters", JSON.stringify(l_store_filters));
            }
            if (location == 'SEARCH') { this.getAllTradersList() }
        }
    },
    computed: {
        getCountries() {
            if (this.store.countryList.length) {
                if (this.searchCountry) {
                    return this.store.countryList.filter((val) => val.countryName.toLowerCase().includes(this.searchCountry.toLowerCase()));
                } else {
                    return this.store.countryList;
                }
            } else {
                return [];
            }
        },
    },
    mounted() {
        let _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".showViews").length) {
                _that.showViews = false;
            }
        });
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".showCountry").length) {
                _that.showCountry = false;
            }
        });
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".Timeframe").length) {
                _that.showTimeframe = false;
            }
        });
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".Currencies").length) {
                _that.showCurrency = false;
            }
        });
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".showSortBy").length) {
                _that.showSortBy = false;
            }
        });
        this.getLocalStorageFilters();
        this.getAllTradersList();
    },
    created() {
        if (this.$route.params.id) {
            this.filterID = this.$route.params.id
            this.tab = 0;
        } else { this.tab = 3; }
        this.viewsFilterBy = this.tab;
        this.customView = this.viewsTextJson[this.viewsFilterBy];
        this.getAllTradersList();
        if (this.store.user.access_token) {
            this.getCustomViewList();
        }
        // if (this.$route.params.timeframe) {
        // }
        if (!this.store.countryList.length) {
            this.store.callCountryList({}, false);
        }
        if (!this.store.TradercurrencyList.length) {
            this.store.callCurrencyList({}, false);
        }
        document.title = `Discover the Top Traders to Follow on ZuluTrade`;
        document.querySelector('meta[name="description"]').setAttribute("content", `Find the best forex and CFD traders to copy on ZuluTrade. Our platform provides advanced tools to help you find and follow the most successful traders.`);
    },
};
</script>
<style>.commonchart {
    height: 150px;
    width: 100%;
}</style>
